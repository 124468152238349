import React from 'react';
import { Carousel } from 'react-bootstrap';

const InstallCarousel = () => {
  return (
    <>
      <Carousel indicators={false} style={{ paddingTop: '0px' }}>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/Installation/installation1.jpg'
            alt='Installation'
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/Installation/Installation2.jpg'
            alt='Installation'
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/Installation/Installation3.jpg'
            alt='Installation'
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/Installation/Installation4.jpg'
            alt='Installation'
          />
        </Carousel.Item>
      </Carousel>
    </>
  );
};

export default InstallCarousel;
