import React from 'react';

const ErrorMessage = ({ error }) => {
  if (error) {
    switch (error.type) {
      case 'required':
        return (
          <p
            style={{
              color: 'red',
              textAlign: 'left',
              marginBottom: '0',
            }}
          >
            This is a required field.
          </p>
        );
      case 'minLength':
        return (
          <p
            style={{
              color: 'red',
              textAlign: 'left',
              marginBottom: '0',
            }}
          >
            This field is too short.
          </p>
        );
      case 'maxLength':
        return (
          <p
            style={{
              color: 'red',
              textAlign: 'left',
              marginBottom: '0',
            }}
          >
            This field is too long.
          </p>
        );
      case 'pattern':
        return (
          <p
            style={{
              color: 'red',
              textAlign: 'left',
              marginBottom: '0',
            }}
          >
            Entry is not valid.
          </p>
        );
      case 'validate':
        return (
          <p
            style={{
              color: 'red',
              textAlign: 'left',
              marginBottom: '0',
            }}
          >
            Please check to verify you've read this message.
          </p>
        );
      default:
        return null;
    }
  }

  return null;
};

export default ErrorMessage;
