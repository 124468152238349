import React from 'react';
import { Container, Card, CardDeck } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from '@iconify/react';
import caretRight from '@iconify-icons/vaadin/caret-right';

const Leadership = () => {
  return (
    <>
      <Container fluid className='px-0 mx-0'>
        <div className='leadershipBreaker'>
          Meet our Leadership Team <Icon icon={caretRight} />
          <Icon icon={caretRight} />
        </div>
        <CardDeck className='mx-0'>
          <Card>
            {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
            <div className='userCircle'>
              <FontAwesomeIcon
                icon={['fas', 'user-circle']}
                size='10x'
                color='slategrey'
              />
            </div>

            <Card.Body>
              <Card.Title>BERNIE SMITH</Card.Title>
              <Card.Subtitle className='mb-2 sub-title'>
                President
              </Card.Subtitle>
              <Card.Text>
                <a className='icon' href='mailto:bsmith@maets.net'>
                  bsmith@maets.net
                </a>
                <br />
                ext. 107
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <small className='text-muted'>Contact: </small>
              <small>
                <a className='icon' href='mailto:bsmith@maets.net'>
                  <FontAwesomeIcon
                    icon={['fas', 'envelope']}
                    size='lg'
                  ></FontAwesomeIcon>
                </a>
              </small>
              <small>
                <a className='icon' href='tel:757-512-6864'>
                  <FontAwesomeIcon
                    icon={['fas', 'phone-alt']}
                    size='lg'
                  ></FontAwesomeIcon>
                </a>
              </small>
            </Card.Footer>
          </Card>

          <Card>
            {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
            <div className='userCircle'>
              <FontAwesomeIcon
                icon={['fas', 'user-circle']}
                size='10x'
                color='slategrey'
              />
            </div>

            <Card.Body>
              <Card.Title>RICHARD MOSER</Card.Title>
              <Card.Subtitle className='mb-2 sub-title'>
                Chief Executive Officer
              </Card.Subtitle>
              <Card.Text>
                <a className='icon' href='mailto:rmoser@maets.net'>
                  rmoser@maets.net
                </a>
                <br />
                ext. 100
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <small className='text-muted'>Contact: </small>
              <small>
                <a className='icon' href='mailto:rmoser@maets.net'>
                  <FontAwesomeIcon
                    icon={['fas', 'envelope']}
                    size='lg'
                  ></FontAwesomeIcon>
                </a>
              </small>
              <small>
                <a className='icon' href='tel:757-512-6864'>
                  <FontAwesomeIcon
                    icon={['fas', 'phone-alt']}
                    size='lg'
                  ></FontAwesomeIcon>
                </a>
              </small>
            </Card.Footer>
          </Card>
          <Card>
            {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
            <div className='userCircle'>
              <FontAwesomeIcon
                icon={['fas', 'user-circle']}
                size='10x'
                color='slategrey'
              />
            </div>

            <Card.Body>
              <Card.Title>MIKE PAYNE</Card.Title>
              <Card.Subtitle className='mb-2 sub-title'>
                General Manager & Contracts Manager
              </Card.Subtitle>
              <Card.Text>
                <a className='icon' href='mailto:mpayne@maets.net'>
                  mpayne@maets.net
                </a>
                <br />
                ext. 116
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <small className='text-muted'>Contact: </small>
              <small>
                <a className='icon' href='mailto:mmeier@maets.net'>
                  <FontAwesomeIcon
                    icon={['fas', 'envelope']}
                    size='lg'
                  ></FontAwesomeIcon>
                </a>
              </small>
              <small>
                <a className='icon' href='tel:757-512-6864'>
                  <FontAwesomeIcon
                    icon={['fas', 'phone-alt']}
                    size='lg'
                  ></FontAwesomeIcon>
                </a>
              </small>
            </Card.Footer>
          </Card>

          {/* <Card>
          <Card.Img variant="top" src="holder.js/100px160" />
          <div className="userCircle">
            <FontAwesomeIcon
              icon={["fas", "user-circle"]}
              size="10x"
              color="slategrey"
            />
          </div>

          <Card.Body>
            <Card.Title>DANNY HENDERSON</Card.Title>
            <Card.Subtitle className="mb-2 sub-title">
              Operations Manager
            </Card.Subtitle>
            <Card.Text>
              <a className="icon" href="mailto:dhenderson@maets.net">
                dhenderson@maets.net
              </a>
              <br />
              ext. 102
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">Contact: </small>
            <small>
              <a className="icon" href="mailto:dhenderson@maets.net">
                <FontAwesomeIcon
                  icon={["fas", "envelope"]}
                  size="lg"
                ></FontAwesomeIcon>
              </a>
            </small>
            <small>
              <a className="icon" href="tel:757-512-6864">
                <FontAwesomeIcon
                  icon={["fas", "phone-alt"]}
                  size="lg"
                ></FontAwesomeIcon>
              </a>
            </small>
          </Card.Footer>
        </Card> */}

          <Card>
            {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
            <div className='userCircle'>
              <FontAwesomeIcon
                icon={['fas', 'user-circle']}
                size='10x'
                color='slategrey'
              />
            </div>

            <Card.Body>
              <Card.Title>JOHN SWINGLE</Card.Title>
              <Card.Subtitle className='mb-2 sub-title'>
                Quality Assurance Manager
              </Card.Subtitle>
              <Card.Text>
                jswingle@maets.net
                <br />
                ext. 201
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <small className='text-muted'>Contact: </small>
              <small>
                <a className='icon' href='mailto:jswingle@maets.net'>
                  <FontAwesomeIcon
                    icon={['fas', 'envelope']}
                    size='lg'
                  ></FontAwesomeIcon>
                </a>
              </small>
              <small>
                <a className='icon' href='tel:757-512-6864'>
                  <FontAwesomeIcon
                    icon={['fas', 'phone-alt']}
                    size='lg'
                  ></FontAwesomeIcon>
                </a>
              </small>
            </Card.Footer>
          </Card>

          <Card>
            {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
            <div className='userCircle'>
              <FontAwesomeIcon
                icon={['fas', 'user-circle']}
                size='10x'
                color='slategrey'
              />
            </div>

            <Card.Body>
              <Card.Title>CATRICE FIELDS</Card.Title>
              <Card.Subtitle className='mb-2 sub-title'>
                Administrative Services Branch Head, HR Manager
              </Card.Subtitle>
              <Card.Text>
                <a className='icon' href='mailto:cfields@maets.net'>
                  cfields@maets.net
                </a>
                <br />
                ext. 109
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <small className='text-muted'>Contact: </small>
              <small>
                <a className='icon' href='mailto:cfields@maets.net'>
                  <FontAwesomeIcon
                    icon={['fas', 'envelope']}
                    size='lg'
                  ></FontAwesomeIcon>
                </a>
              </small>
              <small>
                <a className='icon' href='tel:757-512-6864'>
                  <FontAwesomeIcon
                    icon={['fas', 'phone-alt']}
                    size='lg'
                  ></FontAwesomeIcon>
                </a>
              </small>
            </Card.Footer>
          </Card>

          <Card>
            {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
            <div className='userCircle'>
              <FontAwesomeIcon
                icon={['fas', 'user-circle']}
                size='10x'
                color='slategrey'
              />
            </div>

            <Card.Body>
              <Card.Title>WILLIAM BRADLEY</Card.Title>
              <Card.Subtitle className='mb-2 sub-title'>
                Director of Accounting
              </Card.Subtitle>
              <Card.Text>
                <a className='icon' href='mailto:wbradley@maets.net'>
                  wbradley@maets.net
                </a>
                <br />
                ext. 112
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <small className='text-muted'>Contact: </small>
              <small>
                <a className='icon' href='mailto:wbradley@maets.net'>
                  <FontAwesomeIcon
                    icon={['fas', 'envelope']}
                    size='lg'
                  ></FontAwesomeIcon>
                </a>
              </small>
              <small>
                <a className='icon' href='tel:757-512-6864'>
                  <FontAwesomeIcon
                    icon={['fas', 'phone-alt']}
                    size='lg'
                  ></FontAwesomeIcon>
                </a>
              </small>
            </Card.Footer>
          </Card>

          <Card>
            {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
            <div className='userCircle'>
              <FontAwesomeIcon
                icon={['fas', 'user-circle']}
                size='10x'
                color='slategrey'
              />
            </div>
            <span id='partners'></span>

            <Card.Body>
              <Card.Title>DAVID QUEEN</Card.Title>
              <Card.Subtitle className='mb-2 sub-title'>
                Director of Technical Services
              </Card.Subtitle>
              <Card.Text>
                <a className='icon' href='mailto:dqueen@maets.net'>
                  dqueen@maets.net
                </a>
                <br />
                ext. 110
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <small className='text-muted'>Contact: </small>
              <small>
                <a className='icon' href='mailto:dqueen@maets.net'>
                  <FontAwesomeIcon
                    icon={['fas', 'envelope']}
                    size='lg'
                  ></FontAwesomeIcon>
                </a>
              </small>
              <small>
                <a className='icon' href='tel:757-512-6864'>
                  <FontAwesomeIcon
                    icon={['fas', 'phone-alt']}
                    size='lg'
                  ></FontAwesomeIcon>
                </a>
              </small>
            </Card.Footer>
          </Card>

          <Card>
            {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
            <div className='userCircle'>
              <FontAwesomeIcon
                icon={['fas', 'user-circle']}
                size='10x'
                color='slategrey'
              />
            </div>

            <Card.Body>
              <Card.Title>JOHN KRETZSCHMER</Card.Title>
              <Card.Subtitle className='mb-2 sub-title'>
                Director of Installations
              </Card.Subtitle>
              <Card.Text>
                <a className='icon' href='mailto:jkretzchmer@maets.net'>
                  jkretzchmer@maets.net
                </a>
                <br />
                ext. 130
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <small className='text-muted'>Contact: </small>
              <small>
                <a className='icon' href='mailto:jkretzchmer@maets.net'>
                  <FontAwesomeIcon
                    icon={['fas', 'envelope']}
                    size='lg'
                  ></FontAwesomeIcon>
                </a>
              </small>
              <small>
                <a className='icon' href='tel:757-512-6864'>
                  <FontAwesomeIcon
                    icon={['fas', 'phone-alt']}
                    size='lg'
                  ></FontAwesomeIcon>
                </a>
              </small>
            </Card.Footer>
          </Card>

          <Card>
            {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
            <div className='userCircle'>
              <FontAwesomeIcon
                icon={['fas', 'user-circle']}
                size='10x'
                color='slategrey'
              />
            </div>

            <Card.Body>
              <Card.Title>IRA (WAYNE) KILLMON</Card.Title>
              <Card.Subtitle className='mb-2 sub-title'>
                Production Support Manager Offshore Patrol Craft
              </Card.Subtitle>
              <Card.Text>
                <a
                  className='icon'
                  href='mailto:wkillmon@maets.net
'
                >
                  wkillmon@maets.net
                </a>
                <br />
                ext. 106
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <small className='text-muted'>Contact: </small>
              <small>
                <a className='icon' href='mailto:wkillmon@maets.net'>
                  <FontAwesomeIcon
                    icon={['fas', 'envelope']}
                    size='lg'
                  ></FontAwesomeIcon>
                </a>
              </small>
              <small>
                <a className='icon' href='tel:757-512-6864'>
                  <FontAwesomeIcon
                    icon={['fas', 'phone-alt']}
                    size='lg'
                  ></FontAwesomeIcon>
                </a>
              </small>
            </Card.Footer>
          </Card>

          <Card>
            {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
            <div className='userCircle'>
              <FontAwesomeIcon
                icon={['fas', 'user-circle']}
                size='10x'
                color='slategrey'
              />
            </div>

            <Card.Body>
              <Card.Title>MATTHEW STEWART</Card.Title>
              <Card.Subtitle className='mb-2 sub-title'>
                MSC & Coast Guard Repair Program Manager
              </Card.Subtitle>
              <Card.Text>
                <a
                  className='icon'
                  href='mailto:bsivongsa@maets.net
'
                >
                  mstewart@maets.net
                </a>
                <br />
                ext. 302
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <small className='text-muted'>Contact: </small>
              <small>
                <a
                  className='icon'
                  href='mailto:bsivongsa@maets.net
'
                >
                  <FontAwesomeIcon
                    icon={['fas', 'envelope']}
                    size='lg'
                  ></FontAwesomeIcon>
                </a>
              </small>
              <small>
                <a className='icon' href='tel:757-512-6864'>
                  <FontAwesomeIcon
                    icon={['fas', 'phone-alt']}
                    size='lg'
                  ></FontAwesomeIcon>
                </a>
              </small>
            </Card.Footer>
          </Card>

          <Card>
            {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
            <div className='userCircle'>
              <FontAwesomeIcon
                icon={['fas', 'user-circle']}
                size='10x'
                color='slategrey'
              />
            </div>

            <Card.Body>
              <Card.Title>RONALD SVEUM</Card.Title>
              <Card.Subtitle className='mb-2 sub-title'>
                MSC & NAVSEA Repair Program Manager
              </Card.Subtitle>
              <Card.Text>
                <a className='icon' href='mailto:rsveum@maets.net'>
                  rsveum@maets.net
                </a>
                <br />
                ext. 303
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <small className='text-muted'>Contact: </small>
              <small>
                <a className='icon' href='mailto:rsveum@maets.net'>
                  <FontAwesomeIcon
                    icon={['fas', 'envelope']}
                    size='lg'
                  ></FontAwesomeIcon>
                </a>
              </small>
              <small>
                <a className='icon' href='tel:757-512-6864'>
                  <FontAwesomeIcon
                    icon={['fas', 'phone-alt']}
                    size='lg'
                  ></FontAwesomeIcon>
                </a>
              </small>
            </Card.Footer>
          </Card>
        </CardDeck>
      </Container>
    </>
  );
};

export default Leadership;
