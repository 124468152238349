import React, { useState, useEffect, useRef } from 'react';
import { Container, Navbar, Nav, NavDropdown, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MaetsLogo from './MAETSlogo_original.svg';
import '../fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './header.scss';

const Header = () => {
  const [expanded, setExpanded] = useState(false);
  const [navBackground, setNavBackground] = useState(false);
  const navRef = useRef();
  navRef.current = navBackground;
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 50;
      if (navRef.current !== show) {
        setNavBackground(show);
      }
    };
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Container className='mx-0 px-0' id='header' fluid>
      <Navbar
        collapseOnSelect={true}
        expanded={expanded}
        expand='xl'
        style={{
          transition: '1s ease',
          backgroundColor: navBackground ? '#0d6e39' : '#0000004a',
          color: navBackground ? 'white' : 'black',
        }}
      >
        <Navbar.Brand href='/'>
          <Image fluid className='nav-logo' src={MaetsLogo} alt='MAETS logo' />
          <div className='arrow-right'></div>
        </Navbar.Brand>

        <Navbar.Toggle
          className='navbar-dark'
          aria-controls='responsive-navbar-nav'
          onClick={() => setExpanded(expanded ? false : 'expanded')}
        >
          <span className='icon-bar' />
          <span className='icon-bar' />
          <span className='icon-bar' />
        </Navbar.Toggle>
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav>
            <Nav.Item>
              <Link
                onClick={() => setExpanded(false)}
                className='nav-transition nav-link'
                to='/'
              >
                Home
              </Link>
            </Nav.Item>

            <NavDropdown
              className='nav-transition'
              title='Who We Are'
              id='collasible-nav-dropdown'
            >
              <NavDropdown.Item href='/about'>Who We Are</NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => setExpanded(false)}
                href='/about#background'
              >
                Background
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => setExpanded(false)}
                href='/about#leadership'
              >
                Leadership
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => setExpanded(false)}
                href='/about#partners'
              >
                Partners
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => setExpanded(false)}
                href='/about#naics'
              >
                NAICS Codes
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              className='nav-transition'
              href='/services'
              title='What We Do'
              id='collasible-nav-dropdown'
            >
              <NavDropdown.Item
                onClick={() => setExpanded(false)}
                href='/services'
              >
                What We Do
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => setExpanded(false)}
                href='/services#manufacturing'
              >
                Manufacturing
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => setExpanded(false)}
                href='/services#engineering'
              >
                Engineering
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => setExpanded(false)}
                href='/services#technical'
              >
                Technical
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => setExpanded(false)}
                href='/services#installation'
              >
                Installation
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => setExpanded(false)}
                href='/services#repairs'
              >
                Repair Division
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => setExpanded(false)}
                href='/services#program'
              >
                Program Management
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => setExpanded(false)}
                href='/services#labsupport'
              >
                Laboratory Support
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => setExpanded(false)}
                href='/services#welding'
              >
                ABS Certified Welding Services
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Item>
              <Link
                onClick={() => setExpanded(false)}
                className='nav-transition nav-link'
                to='/contracts'
              >
                Contracts
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link
                onClick={() => setExpanded(false)}
                className='nav-transition nav-link'
                to='/careers'
              >
                Careers
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link
                onClick={() => setExpanded(false)}
                className='nav-transition nav-link'
                to='/contact'
              >
                Contact Us
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link
                onClick={() => setExpanded(false)}
                className='nav-transition nav-link'
                to='/employees'
              >
                {' '}
                <FontAwesomeIcon icon={['fas', 'user']} size='sm' /> Employees
              </Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  );
};

export default Header;
