import React from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import ErrorMessage from './ErrorMessage';

import './apply.scss';

const Apply = () => {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    console.log('Request to submit application');

    const formData = new FormData();
    for (const name in formData) {
      if (name === 'file') continue;

      formData.append(name, data[name]);
    }

    formData.append('file', data.file[0]);
    const uploadResult = await fetch(`https://web.maets.net/api/uploads`, {
      method: 'POST',
      body: formData,
    }).then((response) => response.json());

    try {
      const response = await fetch(`https://web.maets.net/api/apply`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...data,
          path: uploadResult.path,
          fileName: uploadResult.fileName,
        }),
      });

      let applyResult = await response.json();

      if (applyResult.msg === 'success') {
        alert('Thank you for submitting your application!');
        reset(data);
      } else if (applyResult.msg === 'fail') {
        alert(
          'Application failed to send. Please contact our HR representative to place your application.'
        );
        return (error) => console.log('Application failed errors', error);
      }
    } catch (errors) {
      return (errors) => console.log('Errors', errors);
    }
  };

  return (
    <div className='applyWrapper'>
      <h2 className='apply-h2'>Job Application</h2>
      <p>Please complete the form below to apply for a position with us.</p>
      <p className='apply-label'>
        {' '}
        <span>* </span>: Indicates a required field.
      </p>
      <Form id='appForm' onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          <Form.Group as={Col} md='6' controlId='formGridName'>
            <Form.Label className='apply-label'>
              First Name<span>*</span>:
            </Form.Label>
            <Form.Control
              type='text'
              className='apply-input'
              {...register('firstName', {
                required: true,
                maxLength: 20,
                pattern: /^[A-Za-z]+$/i,
              })}
            />
            <ErrorMessage error={errors.firstName} />
          </Form.Group>

          <Form.Group as={Col} md='6' controlId='formGridName'>
            <Form.Label className='apply-label'>
              Last Name<span>*</span>:
            </Form.Label>
            <Form.Control
              type='text'
              className='apply-input'
              {...register('lastName', {
                required: true,
                maxLength: 20,
                pattern: /^[A-Za-z]+$/i,
              })}
            />
            <ErrorMessage error={errors.lastName} />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId='formGridEmail'>
            <Form.Label className='apply-label'>
              {' '}
              Email<span>*</span>:
            </Form.Label>
            <Form.Control
              className='apply-input'
              type='email'
              placeholder='you@example.com'
              {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
            />
            <Form.Text>
              <p
                style={{
                  textAlign: 'left',
                  marginBottom: '0',
                }}
              >
                {' '}
                We'll never share your email with anyone.{' '}
              </p>
              {'   '}
              <ErrorMessage error={errors.email} />
            </Form.Text>
            <Form.Text></Form.Text>
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId='formGridEmail'>
            <Form.Label className='apply-label'>
              Address<span>*</span>:{' '}
            </Form.Label>

            <Form.Control
              className='apply-input'
              type='address'
              placeholder='Street Address*'
              {...register('address1', { required: true })}
            />
            <ErrorMessage error={errors.address1} />

            <Form.Control
              className='apply-input'
              type='address'
              {...register('address2', { required: false })}
            />
            <ErrorMessage error={errors.address2} />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md='4' controlId='formGridCity'>
            <Form.Control
              className='apply-input'
              type='address'
              placeholder='City*'
              {...register('city', { required: true })}
            />
            <ErrorMessage error={errors.city} />
          </Form.Group>
          <Form.Group as={Col} md='4' controlId='formGridState'>
            <Form.Control
              className='apply-input'
              type='address'
              placeholder='State/Province*'
              {...register('state', { required: true })}
            />
            <ErrorMessage error={errors.state} />
          </Form.Group>
          <Form.Group as={Col} md='4' controlId='formGridZip'>
            <Form.Control
              className='apply-input'
              type='address'
              placeholder='Postal / Zip Code*'
              {...register('zipcode', { required: true })}
            />
            <ErrorMessage error={errors.zipcode} />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md='6' controlId='formGridPhone'>
            <Form.Label className='apply-label'>
              Phone Number<span>*</span>:{' '}
            </Form.Label>
            <Form.Control
              className='apply-input'
              type='tel'
              placeholder='000-000-0000'
              {...register('phone', { required: true })}
            />
            <ErrorMessage error={errors.phone} />
          </Form.Group>

          <Form.Group as={Col} md='6' controlId='formGridLinkedIn'>
            <Form.Label className='apply-label'>LinkedIn: </Form.Label>
            <Form.Control
              className='apply-input'
              type='url'
              placeholder='http://'
              {...register('linkedin', { required: false })}
            />
            <ErrorMessage error={errors.linkedin} />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md='6' controlId='formGridPosition'>
            <Form.Label className='apply-label'>
              Position<span>*</span>:{' '}
            </Form.Label>
            <Form.Control
              className='apply-input'
              type='text'
              {...register('position', { required: true })}
            />
            <ErrorMessage error={errors.position} />
          </Form.Group>

          <Form.Group as={Col} md='6' controlId='formGridStartDate'>
            <Form.Label className='apply-label'>
              When can you start?<span>*</span>:{' '}
            </Form.Label>
            <Form.Control
              className='apply-input'
              type='date'
              {...register('date', { required: true })}
            />
            <ErrorMessage error={errors.date} />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId='formGridCoverLetter'>
            <Form.Label className='apply-label'>Cover letter: </Form.Label>
            <Form.Control
              as='textarea'
              rows={10}
              className='apply-input'
              type='text'
              placeholder='Use this space to write your cover letter. If needed, you can increase the viewing size by dragging the bottom right corner down. Max 5000 characters.'
              {...register('text', {
                required: false,
                maxLength: 5000,
              })}
            />
            <ErrorMessage error={errors.text} />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Label htmlFor='file'>Upload Resume: </Form.Label>
          <Form.Control
            className='apply_input'
            name='file'
            type='file'
            {...register('file', { required: false })}
          ></Form.Control>
          <Form.Text>
            <p
              style={{
                textAlign: 'left',
                marginBottom: '0',
              }}
            >
              {' '}
              Accepted file types: .doc, .docx, .pdf, .ppt, ,pptx, .rtf, .txt,
              .xls, .xlsx{' '}
            </p>
            {'   '}
          </Form.Text>

          <ErrorMessage error={errors.uploads} />

          <Form.Row>
            <Form.Group as={Col} controlId='formBasicCheckbox'>
              <Form.Check
                type='checkbox'
                name='validate'
                label='I hereby confirm that the information provided herein is accurate and that the documents submitted along with this
application form are genuine.'
                {...register('validate', {
                  required: true,
                })}
              />
            </Form.Group>
          </Form.Row>
        </Form.Row>
        <ErrorMessage error={errors.validate} />

        <Button type='submit'>Submit</Button>
      </Form>
    </div>
  );
};

export default Apply;
