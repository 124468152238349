import React from 'react';
import { Carousel } from 'react-bootstrap';

const ProgMgmtCarousel = () => {
  return (
    <>
      <Carousel indicators={false} style={{ paddingTop: '0px' }}>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/ProgMgmt/ProgMgmt1.jpg'
            alt='Program Management'
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/ProgMgmt/ProgMgmt2.jpg'
            alt='Program Management'
          />
        </Carousel.Item>
      </Carousel>
    </>
  );
};

export default ProgMgmtCarousel;
