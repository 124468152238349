import React from 'react';
import { Carousel, Button } from 'react-bootstrap';
// import { Icon } from "@iconify/react";
// import hexagonIcon from "@iconify-icons/mdi/hexagon";
import './home.scss';

const CarouselRoll = () => {
  return (
    <Carousel interval={1000000} className='homeCarousel'>
      <Carousel.Item>
        <div className='learnMore'>
          <div className='p'>Learn more about our mission </div>

          <Button variant='outline-dark' href='/about'>
            ABOUT US
          </Button>
        </div>

        <img
          className='d-block w-100 y-move'
          src='./images/Sunset_Ship_full.jpg'
          alt='First slide'
        />
      </Carousel.Item>

      <Carousel.Item>
        <div className='servicesMore'>
          <div className='p'>Learn more about what we do </div>

          <Button variant='outline-dark' href='/services'>
            Services Offered
          </Button>
        </div>
        <img
          className='d-block w-100'
          src='./images/maets8.jpg'
          alt='Third slide Installation'
        />
      </Carousel.Item>
      <Carousel.Item>
        <div className='learnMore'>
          <div className='p3'>
            MAETS is continuously seeking highly skilled and trained
            professionals in the electronics and technologies fields.
          </div>

          <Button variant='outline-dark' href='/careers'>
            VIEW CAREERS
          </Button>
        </div>
        <img
          className='d-block w-100'
          src='./images/installation3.jpg'
          alt='Slide 3 Installation'
        />
      </Carousel.Item>
      <Carousel.Item>
        <div className='learnMore'>
          <div className='p1'>How can we help you? Or you us?</div>

          <Button variant='outline-dark' href='/contact'>
            Contact Us
          </Button>
        </div>
        <img
          className='d-block w-100 y-move'
          src='./images/contactus.jpg'
          alt='Slide 4 Contact Us'
        />
      </Carousel.Item>
    </Carousel>
  );
};

export default CarouselRoll;
