import React from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Container, Card, Button } from 'react-bootstrap';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
// import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { useQuery, gql } from '@apollo/client';

import './jobPosts.scss';

const GET_POST_BY_QUERY = gql`
  query jobListing($id: String!) {
    job: jobListing(id: $id) {
      jobTitle
      education {
        json
      }
      employmentType
      employmentDetails
      dateListed
      description {
        json
      }
      responsibilities {
        json
      }
      responsibilityLevel {
        json
      }
      supervisoryLevel {
        json
      }
      experience {
        json
      }
      certifications {
        json
      }
      clearance
      travel
      physicalRequirements {
        json
      }
      pay {
        json
      }
    }
  }
`;

const JobPost = () => {
  let { id } = useParams();

  const { loading, data, errors } = useQuery(GET_POST_BY_QUERY, {
    variables: { id },
  });
  if (loading) return <span>Loading...</span>;
  if (errors)
    return (
      <p style={{ color: 'red' }}>{errors.map((error) => error.message)}</p>
    );

  return (
    <>
      <Container fluid className='jobListing mx-0 px-0'>
        <>
          <Row className='jobRow px-0'>
            <Card>
              <Card.Header>
                <span>JOB TITLE: </span>
                {data.job.jobTitle}
              </Card.Header>

              <Card.Text>
                <span>EMPLOYMENT TYPE:</span> {data.job.employmentType}
              </Card.Text>
              <Card.Text>
                <span>GENERAL SUMMARY: </span>
                {documentToReactComponents(data.job.description.json)}
              </Card.Text>
              <Card.Text>
                <span>PRINCIPAL DUTIES/RESPONSIBILITIES: </span>
                {documentToReactComponents(data.job.responsibilities.json)}
              </Card.Text>
              <Card.Text>
                <span>RESPONSIBILITY LEVEL: </span>
                {documentToReactComponents(data.job.responsibilityLevel.json)}
              </Card.Text>
              <Card.Text>
                <span>SUPERVISORY LEVEL: </span>
                {documentToReactComponents(data.job.supervisoryLevel.json)}
              </Card.Text>
              <Card.Text>
                <span>REQUIRED EDUCATION: </span>
                {documentToReactComponents(data.job.education.json)}
              </Card.Text>
              <Card.Text>
                <span>LICENSES, CERTIFICATIONS, OR QUALIFICATIONS: </span>
                {documentToReactComponents(data.job.certifications.json)}
              </Card.Text>

              <Card.Text>
                <span>CLEARANCE: </span>
                {data.job.clearance}
              </Card.Text>
              <Card.Text>
                <span>EXPERIENCE REQUIREMENTS: </span>
                {documentToReactComponents(data.job.experience.json)}
              </Card.Text>
              <Card.Text>
                <span>PHYSICAL REQUIREMENTS: </span>
                {documentToReactComponents(data.job.physicalRequirements.json)}
              </Card.Text>
              <Card.Text>
                <span>EMPLOYMENT DETAILS:</span> {data.job.employmentDetails}
              </Card.Text>
              <Card.Text>
                <span>TRAVEL: </span>
                {data.job.travel}
              </Card.Text>
              <Card.Footer>
                <Button id='apply' href='/application' size='lg' block>
                  APPLY NOW
                </Button>
              </Card.Footer>
            </Card>
          </Row>
        </>
      </Container>
      <Row className='jobRow px-0'>
        <Col>
          <div className='eeoStatement'>
            MAETS is an Equal Opportunity Employer. We adhere to a policy of
            making employment decisions without regard to race, color, religion,
            gender, sexual orientation, national origin, citizenship, age,
            disability, or veteran status. We assure you that your opportunity
            for employment with MAETS depends solely on your qualifications.
          </div>
        </Col>
      </Row>
    </>
  );
};

export default JobPost;
