import React from 'react';
import ContactSection from './ContactSection';
import Intro from './Intro';

const Contact = () => {
  return (
    <>
      <Intro />
      <ContactSection />
      <br />
      <br />
    </>
  );
};

export default Contact;
