import React from 'react';
import { Carousel } from 'react-bootstrap';

const ManuCarousel = () => {
  return (
    <>
      <Carousel indicators={false} style={{ paddingTop: '0px' }}>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/Manufacturing/manuf0.jpg'
            alt='Relay Box'
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/Manufacturing/manuf1.jpg'
            alt='Manufacturing'
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/Manufacturing/manuf2.jpg'
            alt='Manufacturing'
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/Manufacturing/manuf3.jpg'
            alt='Manufacturing'
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/Manufacturing/manuf4.jpg'
            alt='Manufacturing'
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/Manufacturing/Fiber_PDU.jpg'
            alt='Fiber & PDU setup'
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/Manufacturing/rack1_2.jpg'
            alt='Racks'
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/Manufacturing/rack3_4.jpg'
            alt='Racks'
          />
        </Carousel.Item>

        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/Manufacturing/rack5_6.jpg'
            alt='Racks'
          />
        </Carousel.Item>

        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/Manufacturing/rack7.jpg'
            alt='Racks'
          />
        </Carousel.Item>

        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/Manufacturing/rack8.jpg'
            alt='Racks'
          />
        </Carousel.Item>

        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/Manufacturing/rack9.jpg'
            alt='Racks'
          />
        </Carousel.Item>

        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/Manufacturing/RelayBox.jpg'
            alt='Racks'
          />
        </Carousel.Item>
      </Carousel>
    </>
  );
};

export default ManuCarousel;
