import React from "react";
import { Row, Col, Image } from "react-bootstrap";

const Background = () => {
  return (
    <>
    <div className="aboutWrapper">
      <Row md={9} className="background">
      <Col id="leadership">
      <h3>Our Mission</h3>
          <p>
          Providing reliable and cost-effective solutions with engineering, technical support, installation, program management, laboratory support services, and depot level maintenance of C5ISR systems.
          </p>
          </Col>
      </Row>
      <Row md={9} className="background ">
        <Col md={6} id="leadership">
      
          <h3>Our Background</h3>
          <p>
            Founded in 2004, MAETS is a Service-Disabled Veteran-Owned Small
            Business headquartered in Chesapeake, Virginia. MAETS is committed
            in providing reliable and cost-effective solutions with engineering,
            technical support, installation, program management, laboratory
            support services, and depot level maintenance of C5ISR systems.
          </p>
          <p style={{ fontWeight: "700" }}>
            DUNS: 17-072-5217
            <br />
            CAGE CODE: 34MM1
          </p>
        </Col>
        <Col md={3} className="backgroundImages">
          <Image src="./images/ISO_Blue.svg" alt="ISO logo" fluid />
          <div className="iso">
            ISO 9001:2015 Compliant
            <br />
            Quality Management System
          </div>
        </Col>
        <Col md={3}>
          <Image src="./images/V3.png" alt="V3 logo" fluid />
        </Col>
      </Row>
    </div>
    </>
  );
};

export default Background;
