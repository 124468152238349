import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { ApolloProvider, InMemoryCache, ApolloClient } from '@apollo/client';

// Global Components
import Header from './Header/Header';
import Footer from './Footer/Footer';
import ScrollArrow from '../hooks/scroll';

//Page components
import Home from '../Pages/Home/Home';
import About from '../Pages/About/About';
import Services from '../Pages/Services/Services';
import Contracts from '../Pages/Contracts/Contracts';
import Careers from '../Pages/Careers/Careers';
import Contact from '../Pages/Contact/Contact';
import Employees from '../Pages/Employees/Employees';
import JobPost from '../Pages/Careers/JobPost';
import Privacy from '../Components/Footer/PrivacyPolicy';
import Apply from '../Pages/Apply/Apply';

//Styling
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri: `https://graphql.contentful.com/content/v1/spaces/ritsa15i8blk`,
  headers: {
    Authorization: `Bearer zC3YVQRvYLczRh-gN4hDNzhEjUkbhrCvZZERYU0Xf2Y`,
  },
});

const App = () => {
  const OnPageChange = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return null;
  };

  return (
    <>
      <Router>
        <OnPageChange />
        <ScrollArrow />
        <Header />
        <div className='App'>
          <Switch>
            <Route exact path='/'>
              <Helmet>
                <title>MAETS | HOME</title>
              </Helmet>
              <Home />
            </Route>
            <Route exact path='/about'>
              <Helmet>
                <title>MAETS | ABOUT US</title>
              </Helmet>
              <About />
            </Route>
            <Route exact path='/services'>
              <Helmet>
                <title>MAETS | WHAT WE DO</title>
              </Helmet>
              <Services />
            </Route>
            <Route exact path='/contracts'>
              <Helmet>
                <title>MAETS | CONTRACTS</title>
              </Helmet>
              <Contracts />
            </Route>

            <Route exact path='/careers'>
              <ApolloProvider client={client}>
                <Helmet>
                  <title>MAETS | CAREERS</title>
                </Helmet>
                <Careers />
              </ApolloProvider>
            </Route>

            <Route exact path='/application'>
              <Helmet>
                <title>MAETS | JOB APPLICATION</title>
              </Helmet>
              <Apply />
            </Route>

            <Route path='/careers/:id'>
              <ApolloProvider client={client}>
                <Helmet>
                  <title>MAETS | JOB LISTING</title>
                </Helmet>
                <JobPost />
              </ApolloProvider>
            </Route>
            <Route exact path='/contact'>
              <Helmet>
                <title>MAETS | CONTACT US</title>
              </Helmet>
              <Contact />
            </Route>
            <Route exact path='/employees'>
              <Helmet>
                <title>MAETS | EMPLOYEES</title>
              </Helmet>
              <Employees />
            </Route>
            <Route exact path='/privacypolicy'>
              <Helmet>
                <title>MAETS | PRIVACY POLICY</title>
              </Helmet>
              <Privacy />
            </Route>

            <Redirect to='/' />
          </Switch>
        </div>
        <Footer />
      </Router>
    </>
  );
};

export default App;
