import React, { useState, useEffect } from 'react';

import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './carousel.scss';

const Carousel = () => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  }, [slider1, slider2]);

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.slider-nav',
  };

  const settingsThumbs = {
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: true,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: '5px',
  };

  const slidesData = [
    {
      id: 1,
      title: 'Maets 1',
      label: '1',
      name: 'maets1.jpg',
    },
    {
      id: 2,
      title: 'Maets 1',
      label: '2',
      name: 'maets2.jpg',
    },
    {
      id: 3,
      title: 'Maets 3',
      label: '3',
      name: 'maets3.jpg',
    },
    {
      id: 4,
      title: 'Maets 4',
      label: '4',
      name: 'maets4.jpg',
    },
    {
      id: 5,
      title: 'Maets 5',
      label: '5',
      name: 'maets5.jpg',
    },
    {
      id: 6,
      title: 'Maets 6',
      label: '6',
      name: 'maets6.jpg',
    },
    {
      id: 7,
      title: 'Maets 7',
      label: '7',
      name: 'maets7.jpg',
    },
    {
      id: 8,
      title: 'Maets 8',
      label: '8',
      name: 'maets8.jpg',
    },
    {
      id: 9,
      title: 'Maets 9',
      label: '9',
      name: 'maets9.jpg',
    },
    {
      id: 10,
      title: 'Maets 10',
      label: '10',
      name: 'maets10.jpg',
    },
    {
      id: 11,
      title: 'Maets 11',
      label: '11',
      name: 'maets11.jpg',
    },
    {
      id: 12,
      title: 'Maets 12',
      label: '12',
      name: 'maets12.jpg',
    },
  ];

  return (
    <>
      <div className='carouselTitle'>OUR EMPLOYEES</div>
      <div className='carousel_A'>
        <div className='slider-wrapper'>
          <Slider
            {...settingsMain}
            asNavFor={nav2}
            ref={(slider) => setSlider1(slider)}
          >
            {slidesData.map((slide) => (
              <div className='slick-slide' key={slide.id}>
                <img
                  className='slick-slide-image'
                  src={`./images/${slide.name}`}
                  alt={slide.title}
                />
                {/* <label className="slick-slide-label">{slide.label}</label> */}
              </div>
            ))}
          </Slider>
          <div className='thumbnail-slider-wrap'>
            <Slider
              {...settingsThumbs}
              asNavFor={nav1}
              ref={(slider) => setSlider2(slider)}
            >
              {slidesData.map((slide) => (
                <div className='slick-slide' key={slide.id}>
                  <img
                    className='slick-slide-image'
                    src={`./images/${slide.name}`}
                    alt={slide.title}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default Carousel;
