import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import doubleCaretRightOutline from '@iconify-icons/teenyicons/double-caret-right-outline';
import caretRight from '@iconify-icons/vaadin/caret-right';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './greensock.css';
import './services.scss';
import './engineering.scss';
import './technical.scss';
import ManuCarousel from './Carousels/ManuCarousel';
import EngCarousel from './Carousels/EngCarousel';
// import Technical from "./Technical";
import InstallCarousel from './Carousels/InstallCarousel';
import ProgMgmt from './Carousels/ProgMgmtCarousel';
import LabCarousel from './Carousels/LabCarousel';
import WeldCarousel from './Carousels/WeldCarousel';
// import Repairs from "./Repairs";

//** gsap js for animation */
function animateFrom(elem, direction) {
  direction = direction || 1;
  var x = 0,
    y = direction * 100;
  if (elem.classList.contains('gs_reveal_fromLeft')) {
    x = -100;
    y = 0;
  } else if (elem.classList.contains('gs_reveal_fromRight')) {
    x = 100;
    y = 0;
  }
  elem.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
  elem.style.opacity = '0';
  gsap.fromTo(
    elem,
    { x: x, y: y, autoAlpha: 0 },
    {
      duration: 1.25,
      x: 0,
      y: 0,
      autoAlpha: 1,
      ease: 'expo',
      overwrite: 'auto',
    }
  );
}

function hide(elem) {
  gsap.set(elem, { autoAlpha: 0 });
}

document.addEventListener('DOMContentLoaded', function () {
  gsap.registerPlugin(ScrollTrigger);

  gsap.utils.toArray('.gs_reveal').forEach(function (elem) {
    hide(elem); // assure that the element is hidden when scrolled into view

    ScrollTrigger.create({
      trigger: elem,
      onEnter: function () {
        animateFrom(elem);
      },
      onEnterBack: function () {
        animateFrom(elem, -1);
      },
      onLeave: function () {
        hide(elem);
      }, // assure that the element is hidden when scrolled into view
    });
  });
});

const Services = () => {
  return (
    <>
      <div className='cInnerContent'>
        <div className='header-section gs_reveal ipsType_center'>
          <h1>
            The <span>MAETS</span> commitment.
          </h1>
          <div id='manufacturing' />
          <p className='cP'>
            MAETS is committed in providing reliable and cost-effective
            solutions with engineering, technical support, installation, program
            management, laboratory support services, and depot level maintenance
            of C5ISR systems. <strong>Here are some of our services.</strong>
          </p>
          <Col id='servicesHeroText'>
            <div className='liTitle'>
              What We Do <Icon icon={doubleCaretRightOutline} />
            </div>
            <ul className='servicesNavs'>
              <li>
                <a href='#engineering'>Engineering</a>
              </li>
              <li>
                <a href='#technical'>Technical</a>
              </li>
              <li>
                <a href='#installation'>Installation</a>
              </li>
              <li>
                <a href='#repairs'>Repair Support</a>
              </li>
              <li>
                <a href='#programMgmt'>Program Management</a>
              </li>

              <li>
                <a href='#labsupport'>Laboratory Support</a>
              </li>
              <li>
                <a href='#welding'>ABS Certified Welding Services</a>
              </li>
              <br />
              <br />
            </ul>
          </Col>
        </div>
        <div className='features'>
          {/* MANUFACTURING */}
          <div className='feature1 ipsSpacer_bottom_double ipsGrid ipsGrid_collapsePhone'>
            <div className='ipsGrid_span7 ipsType_right'>
              <h2 className='heading_large gs_reveal'>
                MANUFACTURING <Icon className='caret' icon={caretRight} />
                <Icon className='caret' icon={caretRight} />
              </h2>
              <div className='gs_reveal'>
                <h4>Performance Confidence</h4>
                <p className='cP'>
                  Dedicated to high quality design, construction, and testing of
                  shipboard electronic equipment racks. Experience includes
                  support of the US Coast Guard Offshore Patrol Cutter (OPC) and
                  National Security Cutter (NSC) programs. MAETS is building on
                  our successes by increasing our footprint in this growing
                  business area, and has recently refined Business Tools,
                  Processes and our Quality Management System in preparation for
                  maximizing efficiency and repeatability, and to maintain the
                  quality MAETS’ customers have grown to expect as we increase
                  production volume in this business area.
                </p>
                <h4>Continuous Process Improvement</h4>
                <Row style={{ display: 'flex', flexFlow: 'row wrap' }}>
                  <Col md={3}>
                    <img
                      src='./images/MAETSMgmtAppICONsquare.jpg'
                      alt=''
                      style={{
                        width: '200px',
                        minWidth: '135px',
                        paddingTop: '15px',
                      }}
                    />
                  </Col>
                  <Col md={9}>
                    <p
                      className='cP'
                      style={{ textAlign: 'left', paddingTop: '10px' }}
                    >
                      <strong>MAETS</strong> is able to offer competitive
                      pricing while reducing overall risk and providing a
                      foundation for Continuous Process Improvement through the
                      use of its own custom Warehouse and Inventory Management
                      System that incorporates a Production Management Module to
                      make more efficient use of data to (1) capture and manage
                      design issues through resolution, (2) capture and apply
                      lessons learned, (3) support bulk procurements to reduce
                      unit costs of material, (4) implement material kitting,
                      (5) apply and manage standard rack configurations, (6)
                      manage design changes through implementation, (7) maintain
                      long-lead material data, (8) manage earned construction
                      progress to inform schedule updates, (9) capture
                      construction performance metrics, (10) manage warranty
                      data on racks and components, and (11) synchronize the
                      activities of engineering, logistics, and construction.
                    </p>
                  </Col>
                </Row>

                <Row style={{ paddingTop: '10px' }}>
                  <Col md={6}>
                    <ul className='engineeringList'>
                      <li>Design/Engineering</li>
                      <li>CAD Design</li>
                      <li>Integration</li>
                      <li>Configuration Management</li>
                      <div id='engineering' />
                      <li>Rack/Enclosure Construction</li>
                      <li>Red Line – As Built Drawings</li>
                    </ul>
                  </Col>
                  <Col md={6}>
                    <ul className='engineeringList'>
                      <li>Testing</li>
                      <li className='circle'>
                        Factory Acceptance Testing (FAT)
                      </li>
                      <li className='circle'>
                        System Operation & Verification Testing (SOVT)
                      </li>
                      <li>Logistics Support</li>
                      <li>Cable Assembly Fabrication (copper and fiber)</li>
                      <li>Prototype Design, Fabrication, and Testing</li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </div>

            <div className='featured-image-container ipsGrid_span5 gs_reveal gs_reveal_fromRight'>
              <div className='card'>
                <ManuCarousel />
              </div>
            </div>
          </div>
          {/* ENGINEERING SUPPORT */}
          <div className='feature ipsSpacer_bottom_double ipsGrid ipsGrid_collapsePhone'>
            <div className='featured-image-container ipsGrid_span5 gs_reveal gs_reveal_fromLeft'>
              <div className='card'>
                <EngCarousel />
              </div>
            </div>

            <div className='ipsGrid_span7 ipsType_left'>
              <h2 className='heading_large gs_reveal'>
                ENGINEERING SUPPORT <Icon className='caret' icon={caretRight} />
                <Icon className='caret' icon={caretRight} />
              </h2>
              <div className='gs_reveal'>
                <h4>Performance Confidence</h4>
                <p className='cP'>
                  <strong>MAETS</strong> offers full-service design engineering
                  capabilities to any modernization program or project afloat or
                  ashore. Our personnel have decades of experience in both Navy
                  and commercial marine engineering and design, as well as
                  shore-based facilities and systems and are familiar with
                  latest military and commercial engineering and d esign
                  standards.
                </p>
                <p className='cP'>
                  MAETS globally provides the following
                  <span style={{ fontSize: '14px', fontWeight: '600' }}>
                    {' '}
                    engineering capabilities
                  </span>
                  :
                </p>
                <ul className='engineeringList'>
                  <li> Systems Integration Engineering and Design</li>
                  <li>Ship and Shore Site Surveys</li>
                  <li>
                    Site Specific engineering design, modification and support
                  </li>
                  <li>Ship Installation Drawing (SID) Development</li>
                  <li>Installation Requirements Drawing (IRD) Development</li>
                  <li>Engineering drawing development</li>
                  <li className='circle'>
                    Preliminary/conceptual design level
                  </li>
                  <li className='circle'>Contractual design level</li>
                  <li className='circle'>Functional design level</li>
                  <li className='circle'>Detailed design level</li>
                  <li>System Operational Verification Test development</li>
                  <li>Test Plan Development</li>
                  <li>Cable plant design and upgrade</li>
                  <li>
                    New Technology Prototyping from concept to production-level
                  </li>
                  <li className='circle'>Preliminary and conceptual design</li>
                  <li className='circle'>Fabrication</li>
                  <li className='circle'>
                    Testing and Life Cycle Support Development
                  </li>
                  <li className='circle'>
                    System functional diagrams from concept to production-level
                  </li>
                  <li>Computer Aided Design (CAD)</li>
                  <li>Computer Aided Engineering (CAE)</li>
                  <div id='technical' />

                  <li>Technical Manual Development </li>
                  <li>Engineering Data Package Development </li>
                </ul>
                <p
                  style={{
                    fontSize: '14px',
                    fontStyle: 'italic',
                    fontWeight: '600',
                    color: '#0d6e39',
                  }}
                >
                  Committed to providing reliable cost-effective Engineering
                  solutions to meet our client’s demands.
                </p>
              </div>
            </div>
          </div>
          {/* TECHNICAL SUPPORT */}
          <div className='feature1 ipsSpacer_bottom_double ipsGrid ipsGrid_collapsePhone'>
            <div className='ipsGrid_span7 ipsType_right'>
              <h2 className='heading_large gs_reveal'>
                TECHNICAL SUPPORT <Icon className='caret' icon={caretRight} />
                <Icon className='caret' icon={caretRight} />
              </h2>
              <div className='gs_reveal'>
                <h4>Performance Confidence</h4>
                <p className='cP'>
                  Dedicated to providing state of the art technical solutions
                  across multiple platforms employing innovative procedures and
                  technical knowledge assuring the highest value results.
                  Comprehensive electrical and electronic expertise in fault
                  detection, preparation, system overhaul, and operational
                  training across the communication spectrum for Military
                  Sealift Command, the U.S. Navy, and the U.S. Coast Guard.
                </p>
                <ul className='engineeringList'>
                  <li>
                    C5ISR (Command, Control, Communications, Computers, Combat
                    Systems, Intelligence, Surveillance, and Reconnaissance)
                  </li>

                  <li>SATCOM Systems: UHF / EHF / SHF / INMARSAT / NGW</li>

                  <li>LOS Systems: LF / MF / HF / VHF / UHF</li>

                  <li>Cryptographic Equipment and Interfacing</li>

                  <li>TV-DTS, including CATV / CCTV Distribution Systems</li>

                  <li>LINK 11 Data Terminals and Radio Equipment</li>

                  <li>NAVMACS II</li>

                  <li>ADNS</li>

                  <li>IT-21/COMPOSE</li>

                  <li>SIPR / NIPR Computer Networks</li>

                  <li>GCCS-M</li>

                  <li>HFIP / SNR</li>

                  <li>Exchange / Mail Servers</li>

                  <li>Cisco Routers</li>

                  <li>Xylan (Alcatel) Switches</li>
                  <span id='installation' />
                  <li>Single Audio System</li>

                  <li>Digital Patching</li>

                  <li>Navigation Systems</li>

                  <li>Authorized Motorola Distributor</li>
                </ul>
              </div>
            </div>

            <div className='featured-image-container ipsGrid_span5 gs_reveal gs_reveal_fromRight'>
              <div className='card'>
                <img src='./images/C5ISR_S.jpg?index=2' alt='' />
              </div>
            </div>
          </div>

          {/* INSTALLATION SUPPORT */}
          <div className='feature ipsSpacer_bottom_double ipsGrid ipsGrid_collapsePhone'>
            <div className='featured-image-container ipsGrid_span5 gs_reveal gs_reveal_fromLeft'>
              <div className='card'>
                <InstallCarousel />
              </div>
            </div>

            <div className='ipsGrid_span7 ipsType_left'>
              <h2 className='heading_large gs_reveal'>
                INSTALLATION DEPARTMENT{' '}
                <Icon className='caret' icon={caretRight} />
                <Icon className='caret' icon={caretRight} />
              </h2>
              <div className='gs_reveal'>
                <p className='cP'>
                  The <strong>MAETS</strong> Installation Department is a full
                  service contractor that brings the resources of a full-service
                  shipyard. Our customers include US Coast Guard, US Navy, and
                  Military Sealift Command (MSC). MAETS has also established
                  exceptional performance with the US Coast Guard and NIWC.
                </p>
                <p className='cP' style={{ fontWeight: '600' }}>
                  MAETS has a wide range of skilled laborers in the following
                  areas:
                </p>
                <ul className='engineeringList'>
                  <li>Full Fabrication of foundations</li>

                  <li>Welding services</li>

                  <li>NDT inspections</li>

                  <li>Electrical</li>

                  <li>Connector builders</li>

                  <li>Fiber builders</li>

                  <span id='repairs' />
                </ul>

                <p
                  className='cP'
                  style={{ paddingTop: '10px', fontWeight: '600' }}
                >
                  We have experience working with the following systems:
                </p>
                <ul className='engineeringList'>
                  <li>C5ISR</li>
                  <li>NMT</li>
                  <li>SSEE Inc F</li>
                  <li>UHF</li>
                  <li>HF</li>
                  <li>VHF</li>
                  <li>TV-DTS</li>
                  <li>INTELLIAN SYSTEM</li>
                  <li>sUAS systems</li>
                  <li>Dual Boat Davit</li>
                  <li>Knuckle Crane installation</li>
                  <li>
                    Various HM&E systems i.e. Flow meter systems for Shafts, AC
                    units and Fan controller units.
                  </li>
                  <li>
                    Damage control systems i.e. ACDS, CGMCS, Fire alarms and
                    temperature gages.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* REPAIR DIVISION */}
          <div className='feature1 ipsSpacer_bottom_double ipsGrid ipsGrid_collapsePhone'>
            <div className='ipsGrid_span7 ipsType_right'>
              <h2 className='heading_large gs_reveal'>
                Repair Division
                <Icon className='caret' icon={caretRight} />
                <Icon className='caret' icon={caretRight} />
              </h2>
              <p className='cP gs_reveal'>
                MAETS Repair Division is a full-service Marine Repair Contractor
                that brings the resources of a full-service shipyard directly to
                the vessel for timely and efficient repair to minimize port
                downtime. We offer a quick response management team that
                supports a large technical workforce without the costly expense
                of a shipyard.    Our customers include the US Navy, including
                Military Sealift Command (MSC), US Coast Guard, commercial
                operators Crowley, and MARAD/USMMI.  As a prime contractor for
                the MSC, MAETS has established an exceptional performance record
                to deliver services and products that meet or exceed customer
                requirements. Our team consists of highly qualified,
                multi-tradesmen with approved credentials to access any base or
                location.   MAETS conducts repairs on vessels in locations World
                Wide, supported by our team of subcontractors.
                <br /> <br />
                <span
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    fontStyle: 'italic',
                  }}
                >
                  “TeamMAETS"
                </span>{' '}
                is the repair motto, working together to provide our customers
                with a job done right.
              </p>
              <p className='cP' style={{ fontWeight: '600' }}>
                Our Wide-Range of Skilled Workers Provide:
              </p>
              <ul className='engineeringList'>
                <li>Full Fabrication and Structural Repair Services</li>

                <li>Pipe Repair and Installation</li>

                <li>Pump and Motor Overhaul</li>

                <li>Valve Repair and Installation</li>

                <li>Electrical Repair and Installation</li>

                <li>Sheet metal and Insulation Services</li>

                <li>Machining expertise</li>

                <li>Structural NDT Testing and Repair</li>
              </ul>
              <br />
              <p className='cP' style={{ fontWeight: '600' }}>
                We offer a range of technical Services:
              </p>
              <div id='program' />

              <ul className='engineeringList'>
                <li>Maintenance and Repair Planning</li>

                <li>Engineering Evaluation and Support</li>

                <li>Shipboard Technical Support</li>
              </ul>
            </div>
            <span id='programMgmt' />

            <div className='featured-image-container ipsGrid_span5 gs_reveal gs_reveal_fromRight'>
              <div className='card'>
                <img src='./images/installation1_S.jpg?index=4' alt='' />
              </div>
            </div>
          </div>

          {/* Program Management */}
          <div className='feature ipsSpacer_bottom_double ipsGrid ipsGrid_collapsePhone'>
            <div className='featured-image-container ipsGrid_span5 gs_reveal gs_reveal_fromLeft'>
              <div className='card'>
                <ProgMgmt />
              </div>
            </div>

            <div className='ipsGrid_span7 ipsType_left'>
              <h2 className='heading_large gs_reveal'>
                Program Management
                <Icon className='caret' icon={caretRight} />
                <Icon className='caret' icon={caretRight} />
              </h2>
              <div className='gs_reveal'>
                <h4>Performance Confidence</h4>
                <p className='cP'>
                  <strong>MSC NGW Program: </strong> Execute ship checks for
                  each class of ship. Provide end to end installation of the NGW
                  equipment suite aboard MSC ships and MSOC Vans. Remove
                  existing BEST equipment (ADE and BDE). Perform pre-staging,
                  testing, wiring and shipping of equipment. Development and
                  execution of Ship Operation Verification Test (SOVT) for each
                  installation. Develop Ship Drawings in AutoCAD of each
                  installation.
                </p>
                <ul className='engineeringList'>
                  <li>Planning</li>

                  <li>Oversight/Execution</li>

                  <li>Schedule Management</li>

                  <li>Risk Management</li>

                  <li>Budget Management</li>

                  <li>Resource Management</li>

                  <li>Status Reporting</li>
                  <span id='labsupport' />
                </ul>
              </div>
            </div>
          </div>
          {/* LABORATORY SUPPORT */}
          <div className='feature1 ipsSpacer_bottom_double ipsGrid ipsGrid_collapsePhone'>
            <div className='ipsGrid_span7 ipsType_right'>
              <h2 className='heading_large gs_reveal'>
                LABORATORY SUPPORT <Icon className='caret' icon={caretRight} />
                <Icon className='caret' icon={caretRight} />
              </h2>
              <div className='gs_reveal'>
                <h4>Services Available:</h4>
                <ul className='engineeringList'>
                  <li>
                    Organizational, Intermediate, and Depot Level Maintenance
                  </li>

                  <li>
                    Radio and Peripheral Equipment Overhauls and Alignments
                    (Refurbish)
                  </li>

                  <li>
                    HF, VHF and UHF (LOS/SATCOM) Antenna and Antenna System
                    Overhauls (Refurbish)
                  </li>

                  <li>
                    Troubleshooting and Repair of all Communication System
                    Components
                  </li>

                  <li>Supply of Ready-to-Issue Components</li>

                  <li>
                    Calibrated Test Equipment, for On-Site and Off-Site Use
                  </li>

                  <li>
                    Authorized service/repair representative on the OE-570
                    System.
                  </li>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <span id='welding' />
                </ul>
              </div>
            </div>

            <div className='featured-image-container ipsGrid_span5 gs_reveal gs_reveal_fromRight'>
              <div className='card'>
                <LabCarousel />
              </div>
            </div>
          </div>
          {/* ABS CERTIFIED WELDING */}
          <div className='feature ipsSpacer_bottom_double ipsGrid ipsGrid_collapsePhone'>
            <div className='featured-image-container ipsGrid_span5 gs_reveal gs_reveal_fromLeft'>
              <div className='card'>
                <WeldCarousel />
              </div>
            </div>

            <div className='ipsGrid_span7 ipsType_left'>
              <h2 className='heading_large gs_reveal'>
                ABS CERTIFIED WELDING{' '}
                <Icon className='caret' icon={caretRight} />
                <Icon className='caret' icon={caretRight} />
              </h2>
              <div className='gs_reveal'>
                <h4>Services Available:</h4>
                <ul className='engineeringList'>
                  <li>5000 Series Aluminum (GMAW-S25 Fillet)</li>

                  <li>Carbon Steel to Stainless (SMAW-S1-S8)</li>

                  <li>Carbon Steel (SMAW-S1)</li>

                  <li>Copper Nickel (GTAW-S34 Buttweld)</li>

                  <li>HY80/HSLA80/ Carbon steel (FCAW-S11A-1)</li>

                  <li>HY100/HSLA100/Carbon steel (SMAW-S11A-2)</li>

                  <li>Stainless Steel (GTAW-S8 Butt Weld)</li>

                  <li>Stainless Steel (GTAW-S8 Socket Weld)</li>

                  <li>Carbon Steel to Cooper Nickel (GTAW-S1-S34 Butt Weld)</li>

                  <li>
                    Carbon Steel to Cooper Nickel (GTAW-S1-S34 Socket Weld)
                  </li>

                  <li>Carbon Steel Stud Gun,3/8” stud to Steel</li>

                  <li>Manual Brazing (h201 Brazing, Grade III)</li>

                  <li>Copper Nickle (GTAW-S34 Socket Weld)</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
