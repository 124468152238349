import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./footer.scss";

const PrivacyPolicy = () => {
  return (
    <>
      <Container fluid className="mx-0 px-0">
        <Row className="privacyHeader mx-0 px-0">
          <Col>
            <Image src="./images/MAETSlogo_original.svg" alt="MAETS logo" />
          </Col>
          <Col>
            <p style={{ textAlign: "end" }}>December 02, 2020</p>
          </Col>
        </Row>
        <Row className="privacyBody mx-0 px-0">
          <h2>Privacy Policy</h2>
          <p>
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.
          </p>
          <p>
            By using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy.{" "}
          </p>
          <h6>Interpretation and Definitions</h6>
          <p>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <h6>Definitions</h6>
          <p>For the purposes of this Privacy Policy:</p>
          <p>
            <ul>
              <li>
                <strong>Company</strong> (referred to as either "the Company",
                "We", "Us" or "Our" in this Agreement) refers to Mid Atlantic
                Technical Solutions, 1501 Crossways Blvd, Suite E.
              </li>
              <li>
                <strong>Cookies</strong> are small files that are placed on Your
                computer, mobile device or any other device by a website,
                containing the details of Your browsing history on that website
                among its many uses.
              </li>
              <li>
                <strong>Country</strong> refers to: Virginia, United States
              </li>
              <li>
                <strong>Device</strong> means any device that can access the
                Service such as a computer, a cellphone or a digital tablet.
              </li>
              <li>
                <strong>Personal Data</strong> is any information that relates
                to an identified or identifiable individual.
              </li>
              <li>
                <strong>Service</strong> refers to the Website.
              </li>
              <li>
                <strong>Service Provider</strong> means any natural or legal
                person who processes the data on behalf of the Company. It
                refers to third-party companies or individuals employed by the
                Company to facilitate the Service, to provide the Service on
                behalf of the Company, to perform services related to the
                Service or to assist the Company in analyzing how the Service is
                used.
              </li>
              <li>
                <strong>Usage Data</strong> refers to data collected
                automatically, either generated by the use of the Service or
                from the Service infrastructure itself (for example, the
                duration of a page visit).
              </li>
              <li>
                <strong>Website</strong> refers to MAETS, accessible from
                <a href="http://www.maets.com"> https://www.maets.com</a>
              </li>
              <li>
                <strong>You</strong> means the individual accessing or using the
                Service, or the company, or other legal entity on behalf of
                which such individual is accessing or using the Service, as
                applicable.
              </li>
            </ul>
          </p>

          <h6>Collecting and Using Your Personal Data</h6>
          <p>
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
            <ul>
              <li>Email address</li>
              <li>Full Name</li>
              <li>Phone Number</li>
              <li>Address, State, Province, ZIP/Postal Code, City</li>
            </ul>
          </p>

          <h6>Usage Data</h6>
          <p>
            {" "}
            Usage Data{" "}
            <strong>
              <u>IS NOT</u>
            </strong>{" "}
            collected when using the Service. Usage data includes, information
            such as Your Device's Internet Protocol address (e.g. IP address),
            browser type, browser version, the pages of our Service that You
            visit, the time and date of Your visit, the time spent on those
            pages, unique device identifiers and other diagnostic data.
          </p>
          <p>
            We{" "}
            <strong>
              <u>WILL NOT</u>
            </strong>{" "}
            collect information that Your browser sends whenever You visit our
            Service or when You access the Service by or through a mobile
            device.
          </p>
          <h6>Tracking Technologies and Cookies</h6>
          <p>
            We{" "}
            <strong>
              <u>DO NOT</u>
            </strong>{" "}
            utilize neither persistent or session Cookies or similar tracking
            technologies to track the activity on Our Service and store certain
            information. Tracking technologies that{" "}
            <strong>
              <u>ARE NOT</u>
            </strong>{" "}
            utilized include flash cookies, web beacons, tags, and scripts to
            collect and track information.{" "}
          </p>
          <h6>Use of Your Personal Data</h6>
          <p>
            The Company may use Personal Data for the following purposes:
            <ul>
              <li>
                To contact You: To contact You by email, telephone calls, SMS,
                or other equivalent forms of electronic communication.
                Communications to You will be strictly limited to information
                regarding possible employment.{" "}
              </li>
              <li>
                To manage Your requests: To attend and manage Your requests to
                Us.
              </li>
              <li>
                For other purposes: Links provided to send job applications,
                resumes, or other information for the purpose of employment are
                retained in accordance with applicable laws and will be disposed
                of when no longer required. Information retained will be used
                for the sole purpose of future employment.{" "}
              </li>
            </ul>
          </p>
          <p>
            We may share Your personal information in the following situations:
            <ul>
              <li>
                <strong>
                  <u>With Your consent:</u>
                </strong>{" "}
                We may disclose Your personal information for any other purpose
                with Your consent.
              </li>
              <li>
                <strong>
                  <u>When required by law enforcement: </u>
                </strong>
                When legally bound by applicable laws, We may provide personal
                information required by law enforcement enforcement entities.{" "}
              </li>
            </ul>
          </p>
          <h6>Retention of Your Personal Data</h6>
          <p>
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.
          </p>
          <h6>
            <em>
              The Company does not collect or retain Usage Data for any purpose.
            </em>
          </h6>
          <h6>Transfer of Your Personal Data</h6>
          <p>
            Your information, including Personal Data, is processed at the
            Company's operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction.{" "}
            <strong>
              Personal Data will never be shared with external entities without
              Your expressed consent.
            </strong>
          </p>
          <p>
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of Your data and other personal
            information.
          </p>
          <h6>Disclosure of Your Personal Data</h6>
          <p>
            <strong>Business Transactions</strong>
            <br />
            If the Company is involved in a merger, acquisition or asset sale,
            Your Personal Data may be transferred. We will provide notice before
            Your Personal Data is transferred and becomes subject to a different
            Privacy Policy.
          </p>
          <p>
            <strong>Law enforcement</strong> <br />
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
          </p>
          <p>
            <strong>Other legal requirements</strong> <br />
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to:
            <ul>
              <li>Comply with a legal obligation</li>
              <li>Protect and defend the rights or property of the Company</li>
              <li>
                Prevent or investigate possible wrongdoing in connection with
                the Service
              </li>
              <li>
                Protect the personal safety of Users of the Service or the
                public
              </li>
              <li>Protect against legal liability</li>
            </ul>
          </p>
          <h6>Security of Your Personal Data</h6>
          <p>
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.
          </p>
          <h6>Links to Other Websites</h6>
          <p>
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party's site. We strongly advise You to
            review the Privacy Policy of every site You visit.
          </p>
          <p>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>
          <h6>Changes to this Privacy Policy</h6>
          <p>
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </p>
          <p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
          <h6>Contact Us</h6>
          <p>
            If you have any questions about this Privacy Policy, You can contact
            us:
            <ul>
              <li>By email: support@maets.net</li>
            </ul>
          </p>
        </Row>
      </Container>
    </>
  );
};

export default PrivacyPolicy;
