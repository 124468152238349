import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "../Components/fontawesome";
import '../Components/App.scss'


const ScrollArrow = () =>{

  const [showScroll, setShowScroll] = useState(false)

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400){
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 400){
      setShowScroll(false)
    }
  };

  const scrollTop = () =>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  window.addEventListener('scroll', checkScrollTop)

  return (
      <>
      <div className="scrollTop" onClick={scrollTop} style={{height: 40, width: 50, display: showScroll ? 'flex' : 'none', alignContent: "center", flexFlow: "row wrap"}}>
    <FontAwesomeIcon icon={['fas', 'arrow-circle-up']} size='2x' />
    <div style={{color: "black", fontSize: "10px", textAlign: "center"}}>Scroll to Top</div>
    </div>
  </>
  );
}

export default ScrollArrow;