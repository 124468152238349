import React from 'react';
import { Carousel } from 'react-bootstrap';

const WeldCarousel = () => {
  return (
    <>
      <Carousel indicators={false} style={{ paddingTop: '0px' }}>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/welding_S.jpg'
            alt='Welding'
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/Welding1.jpg'
            alt='Welding'
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/Welding2.jpg'
            alt='Welding'
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/Welding3.jpg'
            alt='Welding'
          />
        </Carousel.Item>
      </Carousel>
    </>
  );
};

export default WeldCarousel;
