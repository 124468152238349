import React, { useEffect, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
import { TweenMax, Back } from 'gsap';
import doubleCaretRightOutline from '@iconify-icons/teenyicons/double-caret-right-outline';
import Jobs from './JobShort';

import './careers.scss';

const Careers = () => {
  let splashHex = useRef(null);
  useEffect(() => {
    TweenMax.from(splashHex, 1, { opacity: 0, x: 2000, ease: Back.easeOut });
  }, []);

  return (
    <>
      <div id='careers' className='px-0 mx-0'>
        <Container id='careerTopWrapper' fluid className='px-0 mx-0'>
          <Row id='careerHeroWrapper' className='mx-0 px-0'>
            <Col id='careerHeroText'>
              <div className='careerTitle'>
                Careers <Icon icon={doubleCaretRightOutline} />
              </div>
            </Col>
          </Row>
          <Row className='mx-0 px-0 careerFlash'>
            <div
              className='careerHexWrap splashHex'
              ref={(el) => {
                splashHex = el;
              }}
            >
              <div id='pacmanCareer'></div>
              <div className='careerHex'>
                <div className='careerHex_seeking'>
                  <span>MAETS</span> is continuously seeking highly skilled and
                  trained professionals in the electronics and technologies
                  fields.{' '}
                </div>
                <div className='hexLearnMore'>
                  <Link to={`/application`}>
                    APPLY NOW
                    <Icon icon={doubleCaretRightOutline} />{' '}
                  </Link>
                </div>
              </div>
            </div>
          </Row>
          <Row className='mx-0 px-0'>
            <Col className='mx-0 px-0'>
              <Jobs />
            </Col>
          </Row>
        </Container>
        <Row className='mx-0 px-0'>
          <div className='eeoStatement'>
            MAETS is an Equal Opportunity Employer. We adhere to a policy of
            making employment decisions without regard to race, color, religion,
            gender, sexual orientation, national origin, citizenship, age,
            disability, or veteran status. We assure you that your opportunity
            for employment with MAETS depends solely on your qualifications.
          </div>
        </Row>
      </div>
    </>
  );
};

export default Careers;
