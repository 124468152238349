import React from 'react';
import doubleCaretRightOutline from '@iconify-icons/teenyicons/double-caret-right-outline';
import { Icon } from '@iconify/react';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import './careers.scss';

const POST_QUERY = gql`
  {
    jobListingCollection {
      total
      items {
        sys {
          id
        }
        jobTitle
        employmentType
        dateListed
        description {
          json
        }
      }
    }
  }
`;

const Jobs = () => {
  const { loading, data, errors } = useQuery(POST_QUERY);

  if (loading) return <p className='card-header'>Loading...</p>;

  if (errors)
    return (
      <p style={{ color: 'red' }}>
        <b>{errors.map((error) => error.message)}</b>
      </p>
    );

  return (
    <>
      <div id='jobs'>
        <div>
          <Card className='jobCard jobAvailCard'>
            <Card.Text className='jobAvailability'>
              We currently have {data.jobListingCollection.total} open
              positions.
            </Card.Text>
          </Card>
        </div>
        {data.jobListingCollection.items.map((job) => (
          <Card className='jobCard' key={job.sys.id}>
            <Card.Title className='card-header'>
              <Link to={`/careers/${job.sys.id}`} className='jobLink'>
                <span>Title:</span> {job.jobTitle}
              </Link>
            </Card.Title>
            <Card.Body>
              <div className='card-text'>
                <span>Description: </span>
                {documentToReactComponents(job.description.json)}
              </div>
              <Card.Text className='jobLink'>
                <Link to={`/careers/${job.sys.id}`}>
                  VIEW MORE &nbsp;&nbsp;
                  <Icon icon={doubleCaretRightOutline} />
                  <Icon icon={doubleCaretRightOutline} />
                </Link>
              </Card.Text>
            </Card.Body>

            <Card.Footer>
              <Button id='apply' href='/application' size='sm'>
                APPLY NOW
              </Button>
              <div className='text-muted'>Posted on: {job.dateListed}</div>
            </Card.Footer>
          </Card>
        ))}
      </div>
    </>
  );
};

export default Jobs;
