import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Icon } from "@iconify/react";
import caretRight from "@iconify-icons/vaadin/caret-right";

const Partners = () => {
  return (
    <Container fluid className="px-0">
      <div className="teamTitle">
        Team Partners <Icon icon={caretRight} />
        <Icon icon={caretRight} />
      </div>
      <Row id="teamPartners" className="mx-0">
        <Col>
          <ul>
            <li>
              <a
                href="https://www.baesystems.com/en/home"
                target="_blank"
                rel="noopener noreferrer"
              >
                BAE Systems
              </a>
            </li>
            <li>
              <a
                href="https://www.chugach.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Chugach World Services, Inc.
              </a>
            </li>
            <li>
              <a
                href="https://gdit.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                General Dynamics Information Technology, Inc.
              </a>
            </li>
            <li>
              <a
                href="https://www.inmarsatgov.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Inmarsat Government Services
              </a>
            </li>
            <li>
              <a
                href="https://www.l3t.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                L-3 Communications
              </a>
            </li>
            <li>
              <a
                href="https://www.serco.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                SERCO Group
              </a>
            </li>
            <li>
              <a
                href="https://www.scires.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Scientific Research Corporation
              </a>
            </li>
            <li>
              <a
                href="http://www.stf-ltd.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Systems Technology Forum
              </a>
            </li>
            <li>
              <a
                href="http://www.vt-group.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                VT Group (MILCOM)
              </a>
            </li>
            <li>
              <a
                href="http://www.maersklinelimited.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Maersk/3PSC
              </a>
            </li>
          </ul>
        </Col>
        <Col>
          <ul>
            <li>
              <a
                href="https://www.msc.navy.mil/"
                target="_blank"
                rel="noopener noreferrer"
              >
                MSC
              </a>
            </li>

            <li>
              <a
                href="https://www.mil-sat.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Mil-Sat Global Communications
              </a>
            </li>

            <li>
              <a
                href="http://www.detyens.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Detyens Shipyards, Inc.
              </a>
            </li>

            <li>
              <a
                href="https://www.public.navy.mil/navwar/Pages/default.aspx"
                target="_blank"
                rel="noopener noreferrer"
              >
                SPAWAR San Diego/Charleston
              </a>
            </li>

            <li>
              <a
                href="https://www.uscg.mil"
                target="_blank"
                rel="noopener noreferrer"
              >
                US Coast Guard
              </a>
            </li>

            <li>US Army Watercraft OCCM & SLEP</li>

            <li>
              <a
                href="https://www.marinedd.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Marine Design Dynamics, Inc.
              </a>
            </li>

            <li>
              <a
                href="https://www.northeastship.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Northeast Ship Repair
              </a>
            </li>
            <span id="naics"></span>

            <li>
              <a
                href="https://vigor.net/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Vigor Marine
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default Partners;
