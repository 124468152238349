import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../fontawesome';
import './footer.scss';

const Footer = () => {
  return (
    <>
      <Container id='footer' fluid={true} className='px-0 mx-0'>
        <Row className='footer_main mx-0'>
          <div className='footer_columns'>
            <Col className='mContact flexRow'>
              <Link className='footer_h1 footer_column' to='/about'>
                WHO WE ARE
              </Link>
              <ul className='ul_override'>
                <li className='footer_li'>
                  <a href='/about#background'>Background</a>
                </li>
                <li className='footer_li'>
                  <a href='/about#leadership'>Leadership</a>
                </li>
                <li className='footer_li'>
                  <a href='/about#partners'>Partners</a>
                </li>
                <li className='footer_li'>
                  <a href='/about#naics'>NAICS Codes</a>
                </li>
              </ul>
              <Link
                className='footerIcon employee footer_h1 footer_column'
                to='/contracts'
              >
                <FontAwesomeIcon icon={['fas', 'hard-hat']} size='sm' />
                &nbsp;&nbsp;CONTRACTS
              </Link>
            </Col>
            <Col className='mContact flexRow'>
              <Link className='footer_h1 footer_column' to='/services'>
                WHAT WE DO
              </Link>
              <ul className='ul_override'>
                <li className='footer_li'>
                  <a href='/services#engineering'>Engineering</a>
                </li>
                <li className='footer_li'>
                  <a href='/services#technical'>Technical</a>
                </li>
                <li className='footer_li'>
                  <a href='/services#installation'>Installation</a>
                </li>
                <li className='footer_li'>
                  <a href='/services#program'>Program Management</a>
                </li>
                <li className='footer_li'>
                  <a href='/services#lab'>Laboratory Support</a>
                </li>
                <li className='footer_li'>
                  <a href='/services#welding'>ABS Certified Welding Services</a>
                </li>
              </ul>
            </Col>

            <Col className='mContact flexRow'>
              <Link
                className='footerIcon employee footer_h1 footer_column'
                to='/contact'
              >
                <FontAwesomeIcon icon={['fas', 'paper-plane']} size='sm' />
                &nbsp;&nbsp;CONTACT US
              </Link>

              <Link
                className='footerIcon employee footer_h1 footer_column'
                to='/careers'
              >
                <FontAwesomeIcon icon={['fas', 'briefcase']} size='sm' />
                &nbsp;&nbsp;CAREERS
              </Link>
            </Col>
            <Col className='mContact flexRow'>
              <Image
                src='./images/MAETSlogo_original_white.svg'
                alt='MAETS logo'
              />
              <p className='address footer_h1'>
                Mid Atlantic Engineering Technical Services, Inc
              </p>
              <p className='address'>757.512.6864</p>
              <p className='address'>1501 Crossways Blvd Suite E</p>
              <p className='address'>Chesapeake, VA 23320</p>
            </Col>
          </div>
        </Row>
        <Row className='copyright mx-0'>
          <p>
            &copy; 2021 - All Rights Reserved Mid Atlantic Engineering Technical
            Services, Inc |{' '}
            <Link className='privacyLink' to='/privacypolicy'>
              PRIVACY POLICY
            </Link>
          </p>
          <p>
            The appearance of U.S. Department of Defense (DoD) visual
            information does not imply or constitute DoD endorsement.
          </p>
        </Row>
      </Container>
    </>
  );
};

export default Footer;
