import React from 'react';
import { Container, Col, Row, Image } from 'react-bootstrap';

import './home.scss';

const HomeBackground = () => {
  return (
    <>
      <Container fluid className='backgroundHomeWrap mx-0 px-0'>
        <Row className='backgroundHome mx-0 px-0'>
          <div id='pacman'></div>
          <Col md={3} className='back'>
            <div className='h3'>Our Background</div>
            <p>
              Founded in 2004, MAETS is a Service-Disabled Veteran-Owned Small
              Business headquartered in Chesapeake, Virginia. MAETS is committed
              in providing reliable and cost-effective solutions with
              engineering, technical support, installation, program management,
              laboratory support services, and depot level maintenance of C5ISR
              systems.
            </p>
            <p style={{ fontWeight: '700' }}>
              DUNS: 17-072-5217
              <br />
              CAGE CODE: 34MM1
            </p>
          </Col>
          <Col md={3} className='homeBackgroundImages'>
            <Image src='./images/ISO_Blue.svg' alt='ISO logo' fluid />
            <div className='iso'>
              ISO 9001:2015 Compliant
              <br />
              Quality Management System
            </div>
          </Col>
          <Col md={3} className='homeBackgroundImages'>
            <Image src='./images/V3.png' alt='V3 logo' fluid />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default HomeBackground;
