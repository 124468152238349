import React from 'react'
import { Icon, InlineIcon } from '@iconify/react'

import locationIcon from '@iconify/icons-mdi/map-marker-radius-outline'
import phoneIcon from '@iconify/icons-mdi/phone-outline'
import emailIcon from '@iconify/icons-mdi/email-multiple-outline'

import linkedinIcon from '@iconify/icons-mdi/linkedin'

import './info.css'

const Info = () => {

  return (
    <>
    <section className="info">
    <h2 className="info-h2">Contact information</h2>

    <div className="info-details-container">
    <p className="info-detail">
      <InlineIcon icon={locationIcon} /> <a href="https://goo.gl/maps/1ZQ4k4m84HU2" target="_blank" rel="noreferrer">1501 Crossways Blvd Suite E, Chesapeake, VA 23320 </a>
      </p>
    <p className="info-detail">
      <InlineIcon icon={phoneIcon} /> <a href="tel:7575126864">757.512.6864</a>
    </p>
    <p className="info-detail">
      <InlineIcon icon={emailIcon} /> <a href="mailto:support@maets.com" target="_blank" rel="noreferrer">support@maets.net</a>
    </p>
    </div>

    <div className="info-icons-container">
    <p className="info-detail">
       <a href="https://www.linkedin.com/company/mid-atlantic-engineering-technical-services-inc/about/" target="_blank" rel="noreferrer"><Icon className="info-icon" icon={linkedinIcon} /></a> 
    </p>
    </div>
    </section>
    </>
  )
}

// const contactDetails = [
//   {
//     value: '1501 Crossways Blvd Suite E, Chesapeake, VA 23320',
//     icon: locationIcon,
//   },
//   { value: '​757.512.6864', icon: phoneIcon },
//   { value: 'support@maets.com', icon: emailIcon },
// ]

// const renderContactDetails = () =>
//   contactDetails.map(detail => (
    // <p key={detail.value} className="info-detail">
    //   <InlineIcon icon={detail.icon} /> {detail.value}
    // </p>
//   ))

// const renderIcons = () =>
//   [linkedinIcon, ].map((icon, key) => (
//     <Icon icon={icon} key={key} className="info-icon" />
//   ))

// const Info = () => (
//   <section className="info">
//     <h2 className="info-h2">Contact information</h2>

//     <div className="info-details-container">{renderContactDetails()}</div>

//     <div className="info-icons-container">{renderIcons()}</div>
//   </section>
// )

export default Info
