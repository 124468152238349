import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Icon } from "@iconify/react";
import caretRight from "@iconify-icons/vaadin/caret-right";

const Naics = () => {
  return (
    <Container fluid className="px-0">
      <div className="naicsBreaker">
        NAICS CODES <Icon icon={caretRight} />
        <Icon icon={caretRight} />
        <span>MAETS Active Small Business NAICS Codes</span>
      </div>
      <Row className="naicsWrapper mx-0">
        <Col>
          <ul>
            <li>
              <strong>238210</strong> - Electrical Contractors and Other Wiring
              Installation Contractors
            </li>
            <li>
              <strong>334220</strong> - Radio and Television Broadcasting and
              Wireless Communications Equipment Manufacturing
            </li>
            <li>
              <strong>336611</strong> - Ship Building and Repairing
            </li>
            <li>
              <strong>493190</strong> - Other Warehousing and Storage
            </li>
            <li>
              <strong>541330</strong> - Engineering Services
            </li>
            <li>
              <strong>541340</strong> - Drafting Services
            </li>
            <li>
              <strong>541512</strong> - Computer Systems Design Services
            </li>
            <li>
              <strong>541513</strong> - Computer Facilities Management Services
            </li>
            <li>
              <strong>541519</strong> - Other Computer Related Services
            </li>
            <li>
              <strong>541611</strong> - Administrative Management and General
              Management Consulting Services
            </li>
          </ul>
        </Col>
        <Col>
          <ul>
            <li>
              <strong>541613</strong> - Marketing Consulting Services
            </li>
            <li>
              <strong>541690</strong> - Other Scientific and Technical
              Consulting Services
            </li>
            <li>
              <strong>541712</strong> - Research and Development in the
              Physical, Engineering, and Life Sciences (except Biotechnology)
            </li>
            <li>
              <strong>541990</strong> - All Other Professional, Scientific, and
              Technical Services
            </li>
            <li>
              <strong>561621</strong> - Security Systems Services (except
              Locksmiths)
            </li>
            <li>
              <strong>811213</strong> - Communication Equipment Repair and
              Maintenance
            </li>
            <li>
              <strong>811219</strong> - Other Electronic and Precision Equipment
              Repair and Maintenance
            </li>
          </ul>
        </Col>
      </Row>
      <br />
    </Container>
  );
};

export default Naics;
