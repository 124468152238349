import React, { useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import { TweenMax, Back } from 'gsap';
import './home.scss';

import CarouselRoll from './Carousel';
import HomeBackground from './HomeBackground';

const Home = () => {
  let splashText = useRef(null);

  useEffect(() => {
    TweenMax.from(splashText, 1.5, { opacity: 0, x: 1500, ease: Back.easeOut });
  }, []);
  return (
    <>
      <Container fluid className='mx-0 px-0'>
        <div className='splashText'>
          <p
            ref={(el) => {
              splashText = el;
            }}
          >
            SERVICE. DEDICATION. PERFORMANCE.
          </p>
        </div>
        <CarouselRoll />
      </Container>

      <div className='mobileView'>
        <div className='hex'>
          <div className='hex-title'>
            <a href='/about'>Who We Are</a>
          </div>
          <div className='hex-img'>
            <img src='./images/Sunset_Ship_full.jpg' alt='About Us' />
          </div>
        </div>

        <div className='hex'>
          <div className='hex-title'>
            {' '}
            <a href='/services'>What We Do</a>
          </div>
          <div className='hex-img'>
            <img src='./images/maets8.jpg' alt='Services Available' />
          </div>
        </div>

        <div className='hex'>
          <div className='hex-title'>
            <a href='/contracts'>Careers</a>
          </div>
          <div className='hex-img'>
            <img src='./images/installation3.jpg' alt='Careers' />
          </div>
        </div>

        <div className='hex'>
          <div className='hex-title'>
            <a href='/careers'>Contact Us</a>
          </div>
          <div className='hex-img'>
            <img src='./images/contactus.jpg' alt='Contact Us' />
          </div>
        </div>
      </div>
      <HomeBackground />
      <br />
    </>
  );
};

export default Home;
