import React from 'react';
import { Container, Row, Col, Card, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import doubleCaretRightOutline from '@iconify-icons/teenyicons/double-caret-right-outline';
import './contracts.scss';
import '../../Components/fontawesome';

const Home = () => {
  return (
    <>
      <div id='contracts'>
        <Container id='contractsWrapper' fluid className='px-0 mx-0'>
          <Row id='contractsHeroWrapper' className='mx-0 px-0'>
            <Col id='contractsHeroText'>
              <div className='contractsTitle'>
                Contracts <Icon icon={doubleCaretRightOutline} />
              </div>
            </Col>
          </Row>
        </Container>

        <Container fluid className='px-0 contractsBaseWrapper'>
          <div className='contractsBreaker'>
            <h2>MAETS CONTRACT BASE</h2>
          </div>

          <Row>
            <Col lg={9}>
              <Table responsive='lg' striped borderless>
                <tbody>
                  <tr>
                    <td>&#10095; SeaPort-e (Zones 1 – 7) (N00178-14-D-7833)</td>
                  </tr>
                  <tr>
                    <td>
                      &#10095; SeaPort Next Generation (NGEN) (N00178-19-D-8118)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      &#10095; MSC – East Coast General Ship Repair (GSR)
                      (N32205-18-D-4917)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      &#10095; MSC – Ship Electrical Services (N32205-18-D-4705)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      &#10095; NAVAIR - ALRE and Support Equipment AIT
                      (N68335-19-G-0005)
                    </td>
                  </tr>
                  <tr>
                    <td>&#10095; MSC – NGW (Subcontract)</td>
                  </tr>
                  <tr>
                    <td>
                      &#10095; SSC-LANT – Pillars 8(a), SB, Preferred SBSA, LB
                      (Subcontract)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      &#10095; SSC-LANT – Global C4ISR Installation
                      (Subcontract){' '}
                    </td>
                  </tr>
                  <tr>
                    <td>&#10095; USCG – TEDSS (Subcontract)</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col lg={3}>
              <Card className='shadow'>
                <Card.Body>
                  <div className='pocTitle'>
                    {' '}
                    CUSTOMER SATISFACTION
                    <br />
                    POINT OF CONTACT
                  </div>

                  <Card.Title>RICHARD MOSER</Card.Title>
                  <Card.Subtitle className='mb-2 sub-title'>
                    Chief Executive Officer
                  </Card.Subtitle>
                  <Card.Text>
                    <a className='icon' href='mailto:rmoser@maets.net'>
                      rmoser@maets.net
                    </a>
                    <br />
                    904.483.0755
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <small className='text-muted'>Contact: </small>
                  <small>
                    <a className='icon' href='mailto:rmoser@maets.net'>
                      <FontAwesomeIcon
                        icon={['fas', 'envelope']}
                        size='lg'
                      ></FontAwesomeIcon>
                    </a>
                  </small>
                  <small>
                    <a className='icon' href='tel:904-483-0755'>
                      <FontAwesomeIcon
                        icon={['fas', 'phone-alt']}
                        size='lg'
                      ></FontAwesomeIcon>
                    </a>
                  </small>
                </Card.Footer>
              </Card>{' '}
            </Col>
          </Row>
        </Container>
        <div className='subBreaker'>MAETS IDIQ CONTRACT INFORMATION</div>

        <Container fluid className='px-0 contractsBaseWrapperA'>
          <Row>
            <Col lg={9}>
              <p className='contractTitle'>
                Mid Atlantic Regional Maintenance Center (MARMC)
              </p>
              <p>
                Contract Number:<span> N50054-20-D-0010</span>
              </p>
              <p>
                Total Value (Ceiling):<span> $24,999,659</span>
              </p>
              <p>
                Base Period of Performance:
                <span>
                  {' '}
                  September 30, 2020 – September 29, 2021 1 Base Year and 4
                  Option Years (2020-2025)
                </span>
              </p>
              <p>
                Description:{' '}
                <span>
                  Furnish the necessary management, labor, material, repair
                  support, equipment, and facilities to perform industrial
                  fabrication, material procurement to accomplish repair and
                  refurbishment of United States Navy/FMS Surface Combat/C4I
                  Systems listed below:
                </span>
              </p>

              <ul>
                <li>SLQ-32 Antenna Enclosures</li>
                <li>
                  MK-36 and MK-53 Decoy Launching System (DLS) CERTIFICATION
                  REQUIRED
                </li>
                <li>MK-141 MOD 1 Harpoon Launch Support Structure (LSS)</li>
                <li>MK-32 Surface Vessel Torpedo Tube</li>
                <li>OE-82 Series UHF SATCOM Antennas</li>
                <li>
                  AM-6534/SSR-1, AM-7317A/SSR Amplifiers, Radomes, and ancillary
                  equipment
                </li>
              </ul>
            </Col>
            <Col lg={3}>
              <Card className='shadow'>
                <Card.Body>
                  <div className='pocTitle'>MAETS Point of Contact:</div>

                  <Card.Title>Mike Payne</Card.Title>
                  <Card.Subtitle className='mb-2 sub-title'>
                    Contracts Manager
                  </Card.Subtitle>
                  <Card.Text>
                    <a className='icon' href='mailto:mpayne@maets.net'>
                      mpayne@maets.net{' '}
                    </a>
                    <br />
                    757.512.5220
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <small className='text-muted'>Contact: </small>
                  <small>
                    <a className='icon' href='mailto:mpayne@maets.net'>
                      <FontAwesomeIcon
                        icon={['fas', 'envelope']}
                        size='lg'
                      ></FontAwesomeIcon>
                    </a>
                  </small>
                  <small>
                    <a className='icon' href='tel:757-512-5220'>
                      <FontAwesomeIcon
                        icon={['fas', 'phone-alt']}
                        size='lg'
                      ></FontAwesomeIcon>
                    </a>
                  </small>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>

        <Container fluid className='px-0 contractsBaseWrapper'>
          <Row>
            <Col lg={9}>
              <p className='contractTitle'>
                Military Sealift Command (MSC) East Coast General Ship Repair
                (GSR)
              </p>
              <p>
                Contract Number:<span> N32205-18-D-4917</span>
              </p>
              <p>
                Total Value (Ceiling):<span> $93.3M</span>
              </p>
              <p>
                Period of Performance:<span> 01 Apr 2018 – 31 Mar 2023</span>
              </p>
              <p>
                Description:{' '}
                <span>
                  The East Coast MSC GSR contract provides MSC with a broad
                  range of ship repair services for MSC vessels including, but
                  not limited to, pipefitting, welding, machinist work,
                  electrical work, boiler making and repairing, and diesel
                  mechanics. The Contract provides for labor, equipment,
                  supplies, materials, marine repair facilities, and other
                  items, necessary to successfully perform all ship repair work
                  on MSC vessels in accordance with resulting Delivery Orders,
                  to include but not limited to, the Delivery Order Work Item
                  (WI) specifications, drawings, manuals, references, and any
                  and all other documents incorporated into the Delivery Order.
                </span>
              </p>
            </Col>
            <Col lg={3}>
              <Card className='shadow'>
                <Card.Body>
                  <div className='pocTitle'>MAETS Point of Contact:</div>

                  <Card.Title>Mike Payne</Card.Title>
                  <Card.Subtitle className='mb-2 sub-title'>
                    Contracts Manager
                  </Card.Subtitle>
                  <Card.Text>
                    <a className='icon' href='mailto:mpayne@maets.net'>
                      mpayne@maets.net{' '}
                    </a>
                    <br />
                    757.512.5220
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <small className='text-muted'>Contact: </small>
                  <small>
                    <a className='icon' href='mailto:mpayne@maets.net'>
                      <FontAwesomeIcon
                        icon={['fas', 'envelope']}
                        size='lg'
                      ></FontAwesomeIcon>
                    </a>
                  </small>
                  <small>
                    <a className='icon' href='tel:757-512-5220'>
                      <FontAwesomeIcon
                        icon={['fas', 'phone-alt']}
                        size='lg'
                      ></FontAwesomeIcon>
                    </a>
                  </small>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}> </Col>
          </Row>
        </Container>
        <Container fluid className='px-0 contractsBaseWrapperA'>
          <Row>
            <Col lg={9}>
              <p className='contractTitle'>
                Shipboard Electrical Services for Military Sealift Command (MSC)
                Vessels
              </p>
              <p>
                Contract Number:<span> N32205-18-D-4705</span>
              </p>
              <p>
                Total Value (Ceiling):<span> $44.7M</span>
              </p>
              <p>
                Period of Performance:<span> 10 Jan 2018 – 09 Jan 2023</span>
              </p>
              <p>
                Description:{' '}
                <span>
                  The MSC Shipboard Electrical Services contract provides MSC
                  with turn-key repairs and maintenance including structural
                  work, cable wiring, etc., including: inspection, maintenance,
                  repair, service, modification, and installation work on
                  shipboard electrical power generating, power distribution,
                  interior communications, automation, control and monitoring
                  systems and provides OEM certified parts and service
                  technicians that may be required to repair and service any and
                  all machinery and equipment on board MSC vessels.
                </span>
              </p>
            </Col>
            <Col lg={3}>
              <Card className='shadow'>
                <Card.Body>
                  <div className='pocTitle'>MAETS Point of Contact:</div>

                  <Card.Title>Mike Payne</Card.Title>
                  <Card.Subtitle className='mb-2 sub-title'>
                    Contracts Manager
                  </Card.Subtitle>
                  <Card.Text>
                    <a className='icon' href='mailto:mpayne@maets.net'>
                      mpayne@maets.net{' '}
                    </a>
                    <br />
                    757.512.5220
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <small className='text-muted'>Contact: </small>
                  <small>
                    <a className='icon' href='mailto:mpayne@maets.net'>
                      <FontAwesomeIcon
                        icon={['fas', 'envelope']}
                        size='lg'
                      ></FontAwesomeIcon>
                    </a>
                  </small>
                  <small>
                    <a className='icon' href='tel:757-512-5220'>
                      <FontAwesomeIcon
                        icon={['fas', 'phone-alt']}
                        size='lg'
                      ></FontAwesomeIcon>
                    </a>
                  </small>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>

        <Container fluid className='px-0 contractsBaseWrapper'>
          <Row>
            <Col lg={9}>
              <p className='contractTitle'>Seaport Enhanced (Seaport-e)</p>
              <p>
                Contract Number:<span> N00178-14-D-7833</span>
              </p>
              <p>
                Total Value (Ceiling):<span> Unlimited</span>
              </p>
              <p>
                Period of Performance:<span> 04 Apr 2004 – 04 Apr 2019</span>
              </p>
              <p>
                Description:{' '}
                <span>
                  The Seaport-e contract provides services that potentially span
                  the entire spectrum of mission areas supported by the
                  activities and technical capabilities of the Naval Sea Systems
                  Command, Naval Air Systems Command, Space and Naval Warfare
                  Systems Command, Naval Supply Systems Command, Military
                  Sealift Command, Naval Facilities Command, Strategic Systems
                  Programs, Office of Naval Research, DTRA, and the United
                  States Marine Corps, as well as provide professional support
                  services to the overall Navy, DTRA, and Marine Corps
                  organizations. Services within the functional areas identified
                  below, may be performed under this contract for new product
                  areas, programs, or missions, which are assigned to these
                  activities during the life of the contract. Additionally,
                  activities may provide limited support under this contract to
                  other Department of Defense (DoD), non-DoD, or Joint agencies
                  for work that is integrally related to product areas and
                  mission.
                </span>
              </p>
              <p>
                Services to be provided under this contract are categorized into
                22 functional services areas as follows below:
              </p>
              <ol>
                <li>Research and Development Support</li>
                <li>
                  Engineering, System Engineering and Process Engineering
                  Support
                </li>
                <li>Modeling, Simulation, Stimulation, and Analysis Support</li>
                <li>
                  Prototyping, Pre-Production, Model-Making, and Fabrication
                  Support
                </li>
                <li>System Design Documentation and Technical Data Support</li>
                <li>
                  Software Engineering, Development, Programming, and Network
                  Support
                </li>
                <li>
                  Reliability, Maintainability. and Availability (RM&A) Support
                </li>
                <li>
                  Human Factors. Performance, and Usability Engineering Support
                </li>
                <li>System Safety Engineering Support</li>
                <li>Configuration Management (CM) Support</li>
                <li>Quality Assurance (QA) Support</li>
                <li>
                  Information System (1S) Development, information Assurance
                  (IA), and Information Technology (IT) Support
                </li>
                <li>Inactivation and Disposal Support</li>
                <li>Interoperability, Test and Evaluation, Trials Support</li>
                <li>
                  Measurement Facilities, Range, and Instrumentation Support
                </li>
                <li>Logistics Support</li>
                <li>Supply and Provisioning Support</li>
                <li>Training Support</li>
                <li>
                  In-Service Engineering, Fleet Introduction, Installation and
                  Checkout Support
                </li>
                <li>Program Support</li>
                <li>Functional and Administrative Support</li>
                <li>Public Affairs and Multimedia Support</li>
              </ol>
            </Col>
            <Col lg={3}>
              <Card className='shadow'>
                <Card.Body>
                  <div className='pocTitle'>MAETS Point of Contact:</div>

                  <Card.Title>Mike Payne</Card.Title>
                  <Card.Subtitle className='mb-2 sub-title'>
                    Contracts Manager
                  </Card.Subtitle>
                  <Card.Text>
                    <a className='icon' href='mailto:mpayne@maets.net'>
                      mpayne@maets.net{' '}
                    </a>
                    <br />
                    757.512.5220
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <small className='text-muted'>Contact: </small>
                  <small>
                    <a className='icon' href='mailto:mpayne@maets.net'>
                      <FontAwesomeIcon
                        icon={['fas', 'envelope']}
                        size='lg'
                      ></FontAwesomeIcon>
                    </a>
                  </small>
                  <small>
                    <a className='icon' href='tel:757-512-5220'>
                      <FontAwesomeIcon
                        icon={['fas', 'phone-alt']}
                        size='lg'
                      ></FontAwesomeIcon>
                    </a>
                  </small>
                </Card.Footer>
              </Card>
              <div className='documents shadow'>
                <p>Documents:</p>
                <div>
                  <a href='./images/PDFS/ContractFile1.pdf' target='_blank'>
                    {' '}
                    <FontAwesomeIcon
                      icon={['fas', 'file-pdf']}
                      size='3x'
                      className='icon'
                    />
                  </a>
                  <a href='./images/PDFS/ContractFile2.pdf' target='_blank'>
                    <FontAwesomeIcon
                      icon={['fas', 'file-pdf']}
                      size='3x'
                      className='icon'
                    />
                  </a>
                  <a href='./images/PDFS/ContractFile3.pdf' target='_blank'>
                    <FontAwesomeIcon
                      icon={['fas', 'file-pdf']}
                      size='3x'
                      className='icon'
                    />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <Container fluid className='px-0 contractsBaseWrapperA'>
          <Row>
            <Col lg={9}>
              <p className='contractTitle'>Seaport – Next Generation (NGEN)</p>
              <p>
                Contract Number:<span> N00178-19-D-8118</span>
              </p>
              <p>
                Total Value (Ceiling):<span> $10B</span>
              </p>
              <p>
                Period of Performance:<span> 04 Apr 2004 – 04 Apr 2019</span>
              </p>
              <p>
                Description:
                <span>
                  The Seaport – Next Generation (SeaPort-NxG) provides services
                  that potentially span the entire spectrum of mission areas and
                  technical capabilities supported by the Department of the Navy
                  (DON) for the Naval Sea Systems Command, Space and Naval
                  Warfare Systems Command, Naval Supply Systems Command,
                  Military Sealift Command, Naval Facilities Command, Office of
                  Naval Research, or the United States Marine Corps.
                </span>
              </p>
              <p>
                Services fall within 2 categories with 23 functional area
                subcategories identified below:
              </p>
              <p>Categories:</p>
              <ol>
                <li>Engineering Services</li>
                <li>Program Management Services</li>
              </ol>
              <p>Functional Area Subcategories</p>
              <ol>
                <li>
                  Engineering, System Engineering, and Safety and Process
                  Engineering Support
                </li>

                <li>
                  Software Engineering, Development, Programming, and Network
                  Support
                </li>

                <li>
                  In-Service Engineering, Fleet Introduction, Installation and
                  Checkout and Provisioning Support
                </li>

                <li>
                  Measurement Facilities, Range, and Instrumentation Support
                </li>

                <li>Interoperability, Test and Evaluation, Trials Support</li>

                <li>Research and Development Support</li>

                <li>Modeling, Simulation, Stimulation, and Analysis Support</li>

                <li>
                  Prototyping, Pre-Production, Model-Making, and Fabrication
                  Support.
                </li>

                <li>System Design Documentation and Technical Data Support</li>

                <li>
                  Reliability, Maintainability, and Availability (RM&A) Support
                </li>

                <li>Inactivation and Disposal Support</li>

                <li>Biochemical Engineering Support</li>
              </ol>
              <span>
                The contract does not allow for the direct procurement of
                supplies or hardware. Any material or products ordered shall be
                incidental and in direct support of performed services (for
                example, small scale testing equipment, prototypes, or spares.)
              </span>
            </Col>
            <Col lg={3}>
              <Card className='shadow'>
                <Card.Body>
                  <div className='pocTitle'>MAETS Point of Contact:</div>

                  <Card.Title>Mike Payne</Card.Title>
                  <Card.Subtitle className='mb-2 sub-title'>
                    Contracts Manager
                  </Card.Subtitle>
                  <Card.Text>
                    <a className='icon' href='mailto:mpayne@maets.net'>
                      mpayne@maets.net{' '}
                    </a>
                    <br />
                    757.512.5220
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <small className='text-muted'>Contact: </small>
                  <small>
                    <a className='icon' href='mailto:mpayne@maets.net'>
                      <FontAwesomeIcon
                        icon={['fas', 'envelope']}
                        size='lg'
                      ></FontAwesomeIcon>
                    </a>
                  </small>
                  <small>
                    <a className='icon' href='tel:757-512-5220'>
                      <FontAwesomeIcon
                        icon={['fas', 'phone-alt']}
                        size='lg'
                      ></FontAwesomeIcon>
                    </a>
                  </small>
                </Card.Footer>
              </Card>
              <div className='documents shadow'>
                <div>
                  <p>Documents:</p>
                </div>
                <div>
                  <a href='./images/PDFS/ContractFile4.pdf' target='_blank'>
                    <FontAwesomeIcon
                      icon={['fas', 'file-pdf']}
                      size='3x'
                      className='icon'
                    />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <Container fluid className='px-0 contractsBaseWrapper'>
          <Row>
            <Col lg={9}>
              <p className='contractTitle'>
                Aircraft Launch and Recovery Equipment (ALRE) and Support
                Equipment (SE) Shore Site and Shipboard Alteration and
                Installation Team (AIT) (ALRE/SE AIT) Support for NAVAIR
              </p>
              <p>
                Contract Number:<span> N68335-19-G-0005</span>
              </p>
              <p>
                Total Value (Ceiling):<span> Unlimited</span>
              </p>
              <p>
                Period of Performance:<span> 29 Nov 18 – 30 Nov 2023</span>
              </p>
              <p>
                Description:
                <span>
                  The ALRE Basic Ordering Agreement (BOA) provides NAVAIR with
                  qualified personnel to perform shipboard alterations for
                  removal and installation services for the Aircraft Launch and
                  Recovery Equipment (ALRE) Systems. Work will be performed in
                  accordance with the applicable Ship Installation Drawings
                  associated with each ALRE system and the Ship that system will
                  be installed on.
                </span>
              </p>
              <p>These services shall include work in the following areas:</p>
              <ul>
                <li>Quality Assurance (QA)</li>

                <li>System Removal</li>

                <li>Packaging and Shipping</li>

                <li>Debris/Hazardous Material Disposition</li>

                <li>Structural Work</li>

                <li>Welding</li>

                <li>Non-Destructive Testing</li>

                <li>Electrical/Electronics</li>

                <li>Piping/Plumbing</li>

                <li>Fire Watch</li>

                <li>Painting</li>

                <li>Pre-Installation Checkout</li>
              </ul>
            </Col>
            <Col lg={3}>
              <Card className='shadow'>
                <Card.Body>
                  <div className='pocTitle'>MAETS Point of Contact:</div>

                  <Card.Title>Mike Payne</Card.Title>
                  <Card.Subtitle className='mb-2 sub-title'>
                    Contracts Manager
                  </Card.Subtitle>
                  <Card.Text>
                    <a className='icon' href='mailto:mpayne@maets.net'>
                      mpayne@maets.net{' '}
                    </a>
                    <br />
                    757.512.5220
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <small className='text-muted'>Contact: </small>
                  <small>
                    <a className='icon' href='mailto:mpayne@maets.net'>
                      <FontAwesomeIcon
                        icon={['fas', 'envelope']}
                        size='lg'
                      ></FontAwesomeIcon>
                    </a>
                  </small>
                  <small>
                    <a className='icon' href='tel:757-512-5220'>
                      <FontAwesomeIcon
                        icon={['fas', 'phone-alt']}
                        size='lg'
                      ></FontAwesomeIcon>
                    </a>
                  </small>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>

        <Container fluid className='px-0 contractsBaseWrapperA'>
          <Row>
            <Col lg={9}>
              <div className='contractsCompletedTitle'>
                COMPLETED SEAPORT-E TASK ORDERS
                <div className='contractsSubWrap'>
                  <p>N00178-08-D-5498-V701 </p>&nbsp;&nbsp;&nbsp;&nbsp;
                  <span> Date Issued: 01 Aug 2013</span>
                </div>
              </div>
            </Col>
            <Col lg={3}>
              <div className='documents shadow'>
                <div>
                  <p>Documents:</p>
                </div>
                <div>
                  <a href='./images/PDFS/ContractFile5.pdf' target='_blank'>
                    <FontAwesomeIcon
                      icon={['fas', 'file-pdf']}
                      size='3x'
                      className='icon'
                    />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Home;
