import React from 'react'

import './intro.css'

const Intro = () => (
  <div className="intro-container">
    <h2 className="intro-h2">Our Mission</h2>

    <p className="intro-p">
    Providing reliable and cost-effective solutions with engineering, technical support, installation, program management, laboratory support services, and depot level maintenance of C5ISR systems.
    </p>
  </div>
)

export default Intro
