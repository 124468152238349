import React from 'react';
import { Carousel } from 'react-bootstrap';

const LabCarousel = () => {
  return (
    <>
      <Carousel indicators={false} style={{ paddingTop: '0px' }}>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/Lab/Lab1.jpg'
            alt='Testing'
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/Lab/Lab2.jpg'
            alt='Black OE-82 Testing'
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/Lab/Lab3.jpg'
            alt='Gray OE-82 Ready'
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/Lab/Lab4.jpg'
            alt='OE-82 WSC-3'
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/Lab/Lab5.jpg'
            alt='OE-570 Testing'
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/Lab/Lab6.jpg'
            alt='Soldering URA-38'
          />
        </Carousel.Item>
      </Carousel>
    </>
  );
};

export default LabCarousel;
