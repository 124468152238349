// import the library
import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faHome,
  faUser,
  faCode,
  faPaperPlane,
  faUserCircle,
  faEnvelope,
  faPhoneAlt,
  faFilePdf,
  faBriefcase,
  faHardHat,
  faArrowCircleUp
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faHome,
  faUser,
  faCode,
  faPaperPlane,
  faUserCircle,
  faEnvelope,
  faPhoneAlt,
  faFilePdf,
  faBriefcase,
  faHardHat,
  faArrowCircleUp

);
