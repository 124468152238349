import React from 'react';
import { Carousel } from 'react-bootstrap';

const EngCarousel = () => {
  return (
    <>
      <Carousel indicators={false} style={{ paddingTop: '0px' }}>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/Engineering/MAETS-Eng-4.jpg'
            alt='Engineering'
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/Engineering/MAETS-Eng-5.jpg'
            alt='Engineering'
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/Engineering/MAETS-Eng-3.jpg'
            alt='Engineering'
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/Engineering/MAETS-Eng-1.jpg'
            alt='Engineering'
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className='d-block w-100'
            src='./images/ServCarousel/Engineering/MAETS-Eng-2.jpg'
            alt='Engineering'
          />
        </Carousel.Item>
      </Carousel>
    </>
  );
};

export default EngCarousel;
