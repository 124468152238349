import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import sendCircle from '@iconify/icons-mdi/send-circle'; 
import './form.scss';

const Form = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');


    const resetForm = () => {
        setName('John Doe')
        setEmail('you@example.com')
        setMessage('How can we help you? Or you us?')
    }

  const submitRequest = async (e) => {
    e.preventDefault();

  const response = await fetch(`https://web.maets.net/api/send` , {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({name, email, message}),
    });
   
    let result = await response.json(); 
    // console.log("response", response)
    if (result.msg === 'success'){
      alert("Message Sent!"); 
      resetForm()
  }else if(result.msg === 'fail' ){
      alert("Message failed to send.")
  }
  
  };



  return (
    <>
      <form className='form-group contact-us' onSubmit={submitRequest}>
        <h2 className='form-h2'>Send us a message</h2>

        <label className='form-label' htmlFor='Name'>
          Your Name:
        <input
          className='form-input'
          type='text'
          name='name'
          placeholder='John Doe'
          value={name}
          onChange={e => setName(e.target.value)}
          required
        />
        </label>

        <label className='form-label' htmlFor='Email'>
          Your Email:
        <input
          className='form-input'
          type='email'
          name='email'
          placeholder='you@example.com'
          value={email}
          onChange={e => setEmail(e.target.value)}
          required
        />
        </label>

        <label className='form-label' htmlFor='Message'>
          Message:
        <textarea
          className='form-textarea'
          type='text'
          name='message'
          placeholder='How can we help you? Or you us?'
          value={message}
          onChange={e => setMessage(e.target.value)}
          required
        />
        </label>
<div className="submitButton">
        <button className='form-submit' type='submit'>Send 
          <Icon className='form-submit' icon={sendCircle} />
        </button>
        </div>
      </form>
    </>
  );
};
export default Form;
