import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // import { Link } from 'react-router-dom';
// import { Icon } from "@iconify/react";
// import doubleCaretRightOutline from "@iconify-icons/teenyicons/double-caret-right-outline";
import './employees.scss';
import '../../Components/fontawesome';

const Employees = () => {
  return (
    <>
      <Container fluid className='px-0 mx-0'>
        <Row className='intro-emp px-0 mx-0'>
          <Col>
            <Image
              className='imgEmp'
              src='./images/MAETSlogo_original_white.svg'
              alt='MAETS logo'
            />
          </Col>
          <Col>
            <p className='intro-emp-p'>EMPLOYEE PORTAL</p>
          </Col>
        </Row>
        <Row className='emp-sect px-0 mx-0'>
          <Col className='empList'>
            <ul>
              <li>
                <a href='https://maetsmail.maets.net/owa'>
                  <FontAwesomeIcon
                    icon={['fas', 'envelope']}
                    size='lg'
                  ></FontAwesomeIcon>{' '}
                  &nbsp; Employee Email (OWA)
                </a>
              </li>
              <li>
                <a href='https://arrayvpn.maets.net:8443/'>VPN ACCESS</a>
              </li>
              <li>
                <a href='https://costpoint.maets.net:4433/cpweb/'>
                  COSTPOINT/Timesheet Login
                </a>
              </li>
              <li>
                <a href='http://10.2.1.213:8080/'>OpenKM (Internal)</a>
              </li>
              <li>
                <a href='http://10.2.1.214/'>Internal Website</a>
              </li>
              <li>
                <a href='https://moodle.maets.net/'>
                  Training Website (Internal)
                </a>
              </li>
              <li>
                <a href='https://rsa01.maets.net:7004/console-selfservice/SelfService.do'>
                  RSA Token Self Service
                </a>
              </li>
              {/* <li>
                <a href="https://myapps.paychex.com/landing_remote/html">
                  PAYCHEX Login
                </a>
              </li> */}
            </ul>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Employees;
