import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Icon } from "@iconify/react";
import doubleCaretRightOutline from "@iconify-icons/teenyicons/double-caret-right-outline";


import "./about.scss";
import "../../Components/fontawesome";
import Background from "./Background";
import Leadership from "./Leadership";
import Partners from "./Partners";
import Naics from "./NAICS";
import Carousel from "../../Components/Carousel/Carousel";

const About = () => {
  return (
    <>
      <div id="about">
        <Container id="aboutTopWrapper" fluid className="px-0 mx-0">
          <Row id="abtHeroWrapper" className="mx-0 px-0">
            <Col id="aboutHeroText">
              <ul className="aboutTitle">
                <li className="liTitle">
                  Who We Are &nbsp; <Icon icon={doubleCaretRightOutline} />
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
        <span id="background"></span>
        <Background />
        <Leadership />
        <Partners />
        <Naics />
        <Carousel />
      </div>
    </>
  );
};

export default About;
